export function validateEmail(email) {
  let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export const PASSWORD_LENGTH = 4;

export function validatePassword(password) {
  if (password.length < PASSWORD_LENGTH) {
    return false;
  }
  return true;
}
