import { apiCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function getAllTransactionApi() {
  return apiCall(ApiConstants.TRANSACTIONS.GET_ALL_TRANSACTIONS, null, "GET");
}

export async function getTransactionByChargerApi(chargerId) {
  return apiCall(
    `${ApiConstants.TRANSACTIONS.GET_TRANSACTIONS}${chargerId}`,
    null,
    "GET"
  );
}
