import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import navimg from "../../assets/images/navbar_logo.png";
import Modal from "react-modal";
import { useLocation } from "react-router";
import { useLogin, useUpdateLogin } from "../../Context/LoginContext";
import "./Navbar.css";
import { logoutApi } from "../../API/methods/authFlow";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

Modal.setAppElement("#root");
const NAVBAR_HEIGHT = "50px";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  customizeToolbar: {
    minHeight: 36,
    maxHeight: 60,
  },
}));

function Navbar() {
  const classes = useStyles();
  const [modelIsOpen, setModelIsOpen] = useState(false);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [loginDropdown, setLoginDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const location = useLocation();
  const updateIsLogin = useUpdateLogin();
  const isLogIn = useLogin();

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onMouseEnterLogin = () => {
    if (window.innerWidth < 960) {
      setLoginDropdown(false);
    } else {
      setLoginDropdown(true);
    }
  };

  const onMouseLeaveLogin = () => {
    if (window.innerWidth < 960) {
      setLoginDropdown(false);
    } else {
      setLoginDropdown(false);
    }
  };

  const onLogout = () => {
    setModelIsOpen(false);
    // logoutApi()
    //   .then((resp) => {})
    //   .catch((error) => alert("Logout Failed"))
    //   .then(() => {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     updateIsLogin(false);
    //   });
    localStorage.clear();
    sessionStorage.clear();
    updateIsLogin(false);
    document.documentElement.style.setProperty("--side-nav-width", "0px");
  };

  return (
    // <>
    //   <nav className="navbar">
    //     {/* <NavLink to='/Home' className='navbar-logo box-logo' onClick={closeMobileMenu}>
    //       <div className="main-logo-1"><img src={navimg} /></div>
    //       <div>

    //       </div>
    //     </NavLink> */}
    //     <Link to="/Home">
    //       <div className="navbar-logo">
    //         <img className="img-logo" src={navimg} />
    //       </div>
    //     </Link>
    //     <div className="bg-img"> </div>
    //     {location.pathname != "/login" && location.pathname != "/adminLogin" && (
    //       <div className="menu-icon" onClick={handleClick}>
    //         <i className={click ? "fas fa-times" : "fas fa-bars"} />
    //       </div>
    //     )}
    //     <ul className={click ? "nav-menu active" : "nav-menu"}>
    //       <li className="nav-item"></li>
    //       {/* <li className="nav-item">
    //         <NavLink to="/Home" className="nav-links" onClick={closeMobileMenu}>
    //           Home
    //         </NavLink>
    //       </li>

    //       <li
    //         className="nav-item"
    //         onMouseEnter={onMouseEnter}
    //         onMouseLeave={onMouseLeave}
    //       >
    //         <NavLink to="/Home" className="nav-links" onClick={closeMobileMenu}>
    //           Services <i className="fas fa-caret-down" />
    //         </NavLink>
    //         {dropdown && <Dropdown type='service'/>}
    //       </li> */}
    //       {!isLogIn ? (
    //         location.pathname != "/login" &&
    //         location.pathname != "/adminLogin" && (
    //           <li
    //             className="nav-item"
    //             onMouseEnter={onMouseEnterLogin}
    //             onMouseLeave={onMouseLeaveLogin}
    //           >
    //             {/* <NavLink to='/Login' className='nav-links-mobile'
    //           onClick={closeMobileMenu}>
    //           Logout
    //         </NavLink> */}
    //             <NavLink
    //               to="/adminLogin"
    //               className="nav-links"
    //               onClick={closeMobileMenu}
    //             >
    //               Log in
    //               {/* <i className="fas fa-caret-down" /> */}
    //             </NavLink>
    //             {/* {loginDropdown && <Dropdown />} */}
    //           </li>
    //         )
    //       ) : (
    //         <li className="nav-item">
    //           <NavLink
    //             to="#"
    //             className="nav-links"
    //             onClick={() => setModelIsOpen(true)}
    //           >
    //             Logout
    //           </NavLink>
    //         </li>
    //       )}
    //     </ul>
    //   </nav>

    //   <Modal
    //     isOpen={modelIsOpen}
    //     onRequestClose={() => setModelIsOpen(false)}
    //     className="Modal"
    //   >
    //     <div className="modal-header">
    //       <div className="modal-heading">Confirmation</div>

    //       <button className="modal-close" onClick={() => setModelIsOpen(false)}>
    //         &#10006;
    //       </button>
    //     </div>

    //     <div className="modal-body">
    //       <h5>Are you Sure you want to logout?</h5>

    //       <div className="text-right">
    //         <NavLink to="/" className="btn green" onClick={onLogout}>
    //           Logout
    //         </NavLink>

    //         <button className="btn red" onClick={() => setModelIsOpen(false)}>
    //           Cancel
    //         </button>
    //       </div>
    //     </div>

    //     <div className="modal-footer"></div>
    //   </Modal>
    // </>
    <>
      <AppBar
        position="fixed"
        sx={{ height: NAVBAR_HEIGHT }}
        className={classes.appBar}
      >
        <Toolbar className={classes.customizeToolbar}>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            <div className="navbar-logo">
              <Link to="/Home">
                <img className="img-logo" src={navimg} />
              </Link>
            </div>
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          {!isLogIn ? (
            location.pathname != "/login" &&
            location.pathname != "/adminLogin" && (
              <li
                className="nav-item"
                onMouseEnter={onMouseEnterLogin}
                onMouseLeave={onMouseLeaveLogin}
              >
                {/* <NavLink to='/Login' className='nav-links-mobile'
              onClick={closeMobileMenu}>
              Logout
            </NavLink> */}
                <NavLink
                  to="/adminLogin"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Log in
                  {/* <i className="fas fa-caret-down" /> */}
                </NavLink>
                {/* {loginDropdown && <Dropdown />} */}
              </li>
            )
          ) : (
            <li className="nav-item">
              <NavLink
                to="#"
                className="nav-links"
                onClick={() => setModelIsOpen(true)}
              >
                Logout
              </NavLink>
            </li>
          )}
        </Toolbar>
      </AppBar>

      <Modal
        isOpen={modelIsOpen}
        onRequestClose={() => setModelIsOpen(false)}
        className="Modal"
      >
        <div className="modal-header">
          <div className="modal-heading">Confirmation</div>

          <button className="modal-close" onClick={() => setModelIsOpen(false)}>
            &#10006;
          </button>
        </div>

        <div className="modal-body">
          <h5>Are you Sure you want to logout?</h5>

          <div className="text-right">
            <NavLink to="/" className="btn green" onClick={onLogout}>
              Logout
            </NavLink>

            <button className="btn red" onClick={() => setModelIsOpen(false)}>
              Cancel
            </button>
          </div>
        </div>

        <div className="modal-footer"></div>
      </Modal>
    </>
  );
}

export default Navbar;
