import React, { useState, useEffect } from "react";
import "./AddOperator.css";
import {
  TextField,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container
} from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  addUserApi,
  addOperatorToLocationApi,
} from "../../../API/methods/userFlow";
import { getUserDataFromLocalStorage } from "../../../Access/configAccess";
import { feedbackAlert } from "../../../Components/Alerts/Alerts";
import { validateEmail, validatePassword, PASSWORD_LENGTH } from "../../../util";

function AddOperator() {
  let history = useHistory();
  const { locationId } = useParams();
  const [operator, setOperator] = useState({
    email: "",
    password: "",
    company: "",
    locationId: "",
    role: "OPERATOR",
  });

  const [validationError, setError] = useState({
    email: null,
    password: null,
  });

  useEffect(() => {
    getCompanyNLocation();
  }, []);

  const getCompanyNLocation = () => {
    const user = getUserDataFromLocalStorage();
    setOperator({ ...operator, company: user.company, locationId: locationId });
  };

  const onInputChange = (e) => {
    const eventValue = e.target.value;
    const eventName = e.target.name;
    if (eventName === "active") {
      setOperator({ ...operator, [e.target.name]: !e.target.value });
    } else {
      setOperator({ ...operator, [e.target.name]: e.target.value });
    }
    e.preventDefault();
  };

  const addOperator = async (e) => {
    e.preventDefault();

    let checkEmail = validateEmail(operator.email);
    let checkPass = validatePassword(operator.password);
    let errData = {
      email: null,
      password: null,
    };
    if (!checkEmail) errData.email = "Invalid Email";
    if (!checkPass) errData.password = `Minimum ${PASSWORD_LENGTH} character`;
    setError(errData);
    if (!checkEmail || !checkPass) return;

    const payLoad = {
      email: operator.email,
      password: operator.password,
      role: operator.role,
      company: operator.company,
    };
    addUserApi(payLoad)
      .then((response) => {
        if (response) {
          const data = {
            locationId: operator.locationId,
            operatorId: response.data.operatorId,
          };

          addOperatorToLocationApi(data)
            .then((res) => {
              // console.log("addOperator response:  ", res);
              if (res) {
                feedbackAlert(
                  "success",
                  "Operator created Successfully",
                  () => {
                    history.push(`/operator/${locationId}`);
                  }
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };

  return (
    <Container maxWidth='false' disableGutters className="pt-4 pr-2">
      <Paper style={{ padding: 10 }}>
        <h2 className="text-center mb-4 mt-4">Add Operator</h2>
        <form style={{ marginTop: 10 }} onSubmit={(e) => addOperator(e)}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={12}>
              <TextField
                disabled
                id="Name-basic"
                label="Company Name"
                variant="filled"
                fullWidth
                placeholder="Company Name"
                name="company"
                value={operator.company}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                id="email-basic"
                label="Email Id"
                variant="filled"
                fullWidth
                placeholder="Email Id"
                name="email"
                value={operator.email}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
                error={validationError.email ? true : false}
                helperText={validationError.email}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                disabled
                id="locationId"
                label="Location Id"
                variant="filled"
                fullWidth
                type="text"
                placeholder="Location Id"
                name="locationId"
                value={operator.locationId}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <TextField
                disabled
                id="role-basic"
                label="Role"
                variant="filled"
                fullWidth
                placeholder="Role"
                name="role"
                value={operator.role}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField
                id="Password-basic"
                label="Password"
                variant="filled"
                fullWidth
                placeholder="Password"
                name="password"
                value={operator.password}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
                error={validationError.password ? true : false}
                helperText={validationError.password}
              />
            </Grid>
          </Grid>
          <button className="btn green rounded " style={{ marginTop: 20 }}>
            <b>Save</b>
          </button>
        </form>
      </Paper>
    </Container>
  );
}

export default AddOperator;
