import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import "./Home.css";
import Loader from "../../Components/Loader";
import Marker from "../../Components/Marker";
import {
  getAllLocationsApi,
  getAllLocationsByCompanyApi,
  getLocationByLocationIdApi,
} from "../../API/methods/locationFlow";
import { getAllStationFromLocationIDApi } from "../../API/methods/stationFlow";
import { getPricingFromLocationIDApi } from "../../API/methods/priceFlow";
import * as MdIcons from "react-icons/md";
import {
  USER,
  getRolefromLocal,
  getUserDataFromLocalStorage,
} from "../../Access/configAccess";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useLogin } from "../../Context/LoginContext";
import Drawer from "../../Components/Drawer";

const defaultProps = {
  center: {
    lat: 28.615021,
    lng: 77.212709,
  },
  zoom: 12,
};

let access = undefined;
let USERDATA = undefined;

const Home = () => {
  const isLogin = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const [isStationLoading, setIsStationLoading] = useState(false);
  const [stations, setStations] = useState(undefined);
  const [stationDetail, setStationDetail] = useState(undefined);
  const [chargerPrice, setChargerPrice] = useState(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userLocation, setUserLocation] = useState(undefined);
  if (!isLogin) {
    document.documentElement.style.setProperty("--side-nav-width", "0px");
  } else {
    document.documentElement.style.setProperty("--side-nav-width", "250px");
  }
  useEffect(() => {
    access = getRolefromLocal();
    USERDATA = getUserDataFromLocalStorage();
    getStations();
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getCoordinates, handleError);
    } else {
      alert("Geolocation is not supported by this browser");
    }
  };

  const getCoordinates = (position) => {
    // console.log("user location ", position.coords);
    setUserLocation(position.coords);
  };

  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("An unknown error occured.");
    }
  };

  const getStations = async (event) => {
    setIsLoading(true);
    if (access === USER.ADMIN) {
      getAllLocByCompany();
    } else if (access === USER.OPERATOR) {
      getLocationById();
    } else {
      getAllLoc();
    }
  };

  const getAllLoc = async () => {
    getAllLocationsApi()
      .then((response) => {
        // console.log("Location Data", response);
        setStations(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
        // alert("Session is expired..!");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const getAllLocByCompany = async () => {
    getAllLocationsByCompanyApi()
      .then((response) => {
        // console.log("Location Data", response);
        // if (response.response.status === 403) {
        //   alert("Session is expired..!");
        //   localStorage.clear();
        //   sessionStorage.clear();
        // this.props.history.replace("adminlogin");
        // console.log(this.props.history)
        // }
        setStations(response.data);
      })
      .catch(function (error) {
        // console.log("Home error", error.response.status);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const getLocationById = async () => {
    getLocationByLocationIdApi(USERDATA.locationId)
      .then((response) => {
        // console.log("Location Data", response);
        let arrData = [response.data];
        setStations(arrData);
      })
      .catch(function (error) {
        console.log("error", error);
        // alert("Session is expired..!");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const getStationsDetail = async (locationId) => {
    setIsStationLoading(true);
    getAllStationFromLocationIDApi(locationId)
      .then((response) => {
        // console.log("getAllChargers Data", response);
        setStationDetail(response.data[0]);

        getPricingFromLocationIDApi(locationId)
          .then((response) => {
            // console.log("getPricing Data", response);
            setChargerPrice(response.data);
          })
          .catch(function (error) {
            console.log("error", error);
            // alert("Session is expired..!");
          })
          .then(() => {
            setIsStationLoading(false);
          });
      })
      .catch(function (error) {
        console.log("error", error);
        // alert("Session is expired..!");
      })
      .then(() => {
        setIsStationLoading(false);
      });
  };

  const isValidCoordinates = (latitude, longitude) => {
    if (
      isFinite(latitude) &&
      Math.abs(latitude) <= 90 &&
      isFinite(longitude) &&
      Math.abs(longitude) <= 180
    ) {
      return true;
    }
    return false;
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Container maxWidth="false" disableGutters>
      <Drawer />
      {isDrawerOpen
        ? document.documentElement.style.setProperty(
            "--station-side-nav-width",
            "400px"
          )
        : document.documentElement.style.setProperty(
            "--station-side-nav-width",
            "0px"
          )}
      {/* <div
          className={
            this.state.isDrawerOpen
              ? "station-sidebar active "
              : "station-sidebar"
          }
        >
          {this.state.isStationLoading ? (
            <Loader />
          ) : (
            this.state.stationDetail &&
            this.state.chargerPrice && (
              <div>
                <div className="address-box">
                  <div>{this.state.stationDetail.description}</div>
                  <div className="address">
                    <MdIcons.MdLocationOn className="location-icon" />

                    {`${this.state.stationDetail.street}, ${this.state.stationDetail.landmark}, ${this.state.stationDetail.city}, ${this.state.stationDetail.country}, ${this.state.stationDetail.zip}`}
                  </div>
                </div>

                <div className="chargers-view">
                  {this.state.chargerPrice &&
                    this.state.chargerPrice.map((item) => {
                      return (
                        <div className="charger-card">
                          <div className="charger-Txt">
                            <div className="cgrName">{item.meterType}</div>
                            <div className="cgrPrice">
                              ₹ {item.chargerPrice}/min
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )
          )}
        </div> */}
      <div className="mapContainer">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDoryxpc5rloTD-4PwBBwvnHqsHKWVOiDg",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {stations &&
            stations.map((item) => {
              if (!isValidCoordinates(item.latitude, item.longitude)) return;
              return (
                <Marker
                  // onPress={() => {
                  //   this.getStationsDetail(item.id);
                  //   this.setState({ isDrawerOpen: true });
                  // }}
                  key={item.id}
                  lat={item.latitude}
                  lng={item.longitude}
                  data={item}
                  name={`${item.description}\n${item.street}, ${item.landmark}, ${item.city}, ${item.country}`}
                />
              );
            })}
          {userLocation && (
            <Marker
              backgroundColor="#40c4ff"
              key="userLocation"
              lat={userLocation.latitude}
              lng={userLocation.longitude}
              name="My Location"
            />
          )}
        </GoogleMapReact>
      </div>
    </Container>
  );
};

export default withRouter(Home);
