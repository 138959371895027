import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import Table from "../../Components/Table/Table";
import { getAllStationFromLocationIDApi } from "../../API/methods/stationFlow";
import { USER, getRolefromLocal } from "../../Access/configAccess";
import { Paper, Container } from "@material-ui/core";

const ChargerManagement = () => {
  const { locationId } = useParams();
  const access = getRolefromLocal();
  const [Chargers, setCharger] = useState([]);
  useEffect(() => {
    loadChargers();
  }, []);

  const loadChargers = async () => {
    getAllStationFromLocationIDApi(locationId)
      .then((response) => {
        // console.log("getAllChargers Data", JSON.stringify(response.data));
        setCharger(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
        // alert("Session is expired..!");
      });
  };

  const columns = [
    {
      Header: "ChargeBox Serial No.",
      accessor: "chargerboxserialno",
    },
    {
      Header: "Vendor",
      accessor: "vendor",
    },
    {
      Header: "Model",
      accessor: "model",
    },
    // {
    //   Header: "iccid",
    //   accessor: "iccid",
    // },
    // {
    //   Header: "Ocpp Protocol",
    //   accessor: "ocppprotocol",
    // },
    // {
    //   Header: "Firmware Version",
    //   accessor: "firmwareversion",
    // },
    {
      Header: "Key",
      accessor: "key",
    },
    {
      id: "action",
      Header: "Action",
      accessor: "id",
      Cell: ({ cell }) => (
        <div>
          <Link
            className="btn info-transparent icon-l rounded"
            to={`/viewChargerDetails/${cell.row.original.key}`}
            title="View Charger"
          >
            <AiIcons.AiOutlineEye />
          </Link>

          {access === USER.ADMIN && (
            <Link
              className="btn info-transparent icon-l rounded"
              to={`/editcharger/${cell.row.original.key}`}
              title="Edit Charger"
            >
              <AiIcons.AiFillEdit />
            </Link>
          )}

          {access === USER.ADMIN && (
            <Link
              className="btn info-transparent icon-l rounded"
              to={`/transaction/${cell.row.original.key}`}
              title="Transactions"
            >
              <FcIcons.FcDocument />
            </Link>
          )}
        </div>
      ),
    },
  ];

  return (
    <Container maxWidth='false' disableGutters>
      <div className="tables pt-4 pr-2">
        {access === USER.ADMIN && (
          <Link
            className="btn green icon-l"
            to={`/createCharger/${locationId}`}
          >
            <AiIcons.AiOutlinePlusCircle />
            Add New Charger
          </Link>
        )}
        {Chargers && Chargers.length > 0 && (
          <Paper>
            <Table columns={columns} data={Chargers} />
          </Paper>
        )}
      </div>
    </Container>
  );
};

export default ChargerManagement;
