import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import ConfirmAlert from './ConfirmAlert'
import FeedbackAlert from "./FeedbackAlert";

export function confirmdelete  (heading, message, func) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <ConfirmAlert
                    heading={heading}
                    message={message}
                    yes={() => { func(); onClose(); }}
                    no={onClose}
                />
            )
        }
    })
}

export function feedbackAlert (type, message, func) {
    confirmAlert({
        customUI: ({ onClose }) => {

            return (
                <FeedbackAlert
                    type={type}
                    message={message}
                    no={() => { func(); onClose() }}
                />
            )
        }
    })
}


