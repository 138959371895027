import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as HiIcons from "react-icons/hi";
import Table from "../../Components/Table/Table";
import { Paper, Container } from "@material-ui/core";
import {
  USER,
  getRolefromLocal,
  getUserDataFromLocalStorage,
} from "../../Access/configAccess";
import {
  getAllLocationsByCompanyApi,
  getAllLocationsApi,
  getLocationByLocationIdApi,
} from "../../API/methods/locationFlow";

const LocationManagement = () => {
  const [locations, setlocation] = useState([]);
  useEffect(() => {
    loadLocations();
  }, []);
  const access = getRolefromLocal();
  const USERDATA = getUserDataFromLocalStorage();
  // console.log("USERDATA: ", USERDATA);

  const addressFormation = (LocData) => {
    let newData = [];
    LocData.map((itm) => {
      let address = `${itm.street}, ${itm.city}, ${itm.country}, ${itm.zip}`;
      newData.push({ ...itm, address });
    });
    return newData;
  };

  const loadLocations = async () => {
    if (access === USER.SUPERADMIN) {
      getAllLocationsApi()
        .then((response) => {
          if (response) {
            // console.log("loadLocations Data", response.data);
            let Data = addressFormation(response.data);
            setlocation(Data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else if (access === USER.ADMIN) {
      getAllLocationsByCompanyApi()
        .then((response) => {
          if (response) {
            // console.log("loadLocations Data", response);
            let Data = addressFormation(response.data);
            setlocation(Data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else if (access === USER.OPERATOR) {
      getLocationByLocationIdApi(USERDATA.locationId)
        .then((response) => {
          if (response) {
            // console.log("loadLocations Data", response);
            let arrData = [response.data];
            let Data = addressFormation(arrData);
            setlocation(Data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const columns = [
    {
      Header: "Company",
      accessor: "company",
    },
    {
      Header: "Station Name",
      accessor: "description",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      id: "action",
      Header: "",
      accessor: "id",
      Cell: ({ cell }) => (
        <div>
          <Link
            className="btn info-transparent icon-l rounded"
            to={`/viewlocation/${cell.row.original.id}`}
            title="View Location"
          >
            <AiIcons.AiOutlineEye />
          </Link>
          {access === USER.ADMIN && (
            <Link
              className="btn blue-transparent icon-l rounded"
              to={`/editlocation/${cell.row.original.id}`}
              title="Edit Location"
            >
              <AiIcons.AiFillEdit />
            </Link>
          )}
          <Link
            className="btn green-transparent icon-l rounded"
            to={`/ChargerManagement/${cell.row.original.id}`}
            title="Charger"
          >
            <MdIcons.MdEvStation />
          </Link>

          {(access === USER.ADMIN || access === USER.SUPERADMIN) && (
            <Link
              className="btn info-transparent icon-l rounded"
              to={`/operator/${cell.row.original.id}`}
              title="Operators"
            >
              <FaIcons.FaUsers />
            </Link>
          )}
          <Link
            className="btn blue-transparent icon-l rounded"
            to={`/chargerPricing/${cell.row.original.id}`}
            title="Charger Price"
          >
            {/* <MdIcons.MdMonetizationOn /> */}
            <HiIcons.HiCurrencyRupee />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Container maxWidth="false" disableGutters>
      <div className="tables pt-4">
        {access === USER.ADMIN && (
          <Link className="btn green icon-l" to={`/CreateLocation`}>
            <AiIcons.AiOutlinePlusCircle />
            New Location
          </Link>
        )}
        {locations && locations.length > 0 && (
          <Paper className="pr-2">
            <Table columns={columns} data={locations} />
          </Paper>
        )}
      </div>
    </Container>
  );
};
export default LocationManagement;
