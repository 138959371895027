import React, { useState, useEffect } from "react";
import { useHistory, Link, NavLink, useParams } from "react-router-dom";
import "./Locations.css";
import { feedbackAlert, confirmdelete } from "../../Components/Alerts/Alerts";
import {
  TextField,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Container,
} from "@material-ui/core";
import { countryList } from "./CountryList";
import { createLocationApi } from "../../API/methods/locationFlow";
import { getUserDataFromLocalStorage } from "../../Access/configAccess";

const CreateLocation = () => {
  let history = useHistory();
  const user = useParams();
  const [Location, setlocation] = useState({
    city: "",
    country: "",
    description: "",
    landmark: "",
    latitude: "",
    longitude: "",
    street: "",
    zip: "",
    company: user.company,
  });

  const [validLong, setValidLong] = useState(true);
  const [validLan, setValidLan] = useState(true);

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    const user = getUserDataFromLocalStorage();
    setlocation({ ...Location, company: user.company });
  };

  const {
    city,
    country,
    description,
    landmark,
    latitude,
    longitude,
    street,
    zip,
    company,
  } = Location;

  const onInputChange = (e) => {
    // console.log(e);
    const eventValue = e.target.value;
    const eventName = e.target.name;
    if (eventName === "active") {
      setlocation({ ...Location, [e.target.name]: !e.target.value });
    } else if (eventName === "latitude") {
      setValidLan(isValidLatitude(e.target.value));
      setlocation({ ...Location, [e.target.name]: e.target.value });
    } else if (eventName === "longitude") {
      setValidLong(isValidLongitude(e.target.value));
      setlocation({ ...Location, [e.target.name]: e.target.value });
    } else {
      setlocation({ ...Location, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if(!validLan){
      window.alert('Enter valid latitude');
      return;
    }
    if(!validLong){
      window.alert('Enter valid longitude');
      return;
    }
    createLocationApi(Location)
      .then((response) => {
        if (response) {
          feedbackAlert("success", "location created Successfully", () => {
            history.push("/LocationManagement");
          });
        }
      })
      .catch((error) => alert("Create Location failed"));
  };

  const handleChange = (event) => {
    setlocation({ ...Location, country: event.target.value });
  };

  const isValidLatitude = (latitude) => {
    if (isFinite(latitude) && Math.abs(latitude) <= 90) {
      return true;
    }
    return false;
  };

  const isValidLongitude = (longitude) => {
    if (isFinite(longitude) && Math.abs(longitude) <= 180) {
      return true;
    }
    return false;
  };

  return (
    // <div className="container">
    <Container maxWidth={false} disableGutters>
      {/* <div className="w-150 mx-auto shadow p-5"> */}
      <div className="form-container">
        <Paper style={{ padding: 20 }}>
          <h2 className="text-center mb-4">Location Details</h2>

          <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="Description-basic"
                  label="Description"
                  variant="filled"
                  fullWidth
                  placeholder="Description"
                  name="description"
                  value={description}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Street-basic"
                  label="Street"
                  variant="filled"
                  fullWidth
                  placeholder="Street"
                  name="street"
                  value={street}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Landmark-basic"
                  label="Landmark"
                  variant="filled"
                  fullWidth
                  placeholder="Landmark"
                  name="landmark"
                  value={landmark}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="City"
                  label="City"
                  variant="filled"
                  fullWidth
                  type="text"
                  placeholder="City"
                  name="city"
                  value={city}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  // item
                  xs={12}
                  sm={6}
                  size="small"
                >
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Country"
                    onChange={handleChange}
                    fullWidth
                  >
                    {countryList.map((itm) => {
                      return <MenuItem value={itm} key={itm}>{itm}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="Zip-basic"
                  label="Zip"
                  variant="filled"
                  fullWidth
                  placeholder="Zip"
                  name="zip"
                  value={zip}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="company-basic"
                  label="Company"
                  variant="filled"
                  fullWidth
                  placeholder="Company"
                  name="company"
                  value={company}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="latitude-basic"
                  label="Latitude"
                  variant="filled"
                  fullWidth
                  error={!validLan}
                  helperText="Use decimal degrees format that range from -90 to 90 for latitude. e.g. 28.615021"
                  placeholder="Latitude"
                  name="latitude"
                  value={latitude}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="longitude-basic"
                  label="Longitude"
                  variant="filled"
                  fullWidth
                  error={!validLong}
                  helperText="Use decimal degrees format that range from -180 to 180 for longitude. e.g. 77.212709"
                  placeholder="Longitude"
                  name="longitude"
                  value={longitude}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
            </Grid>
            <button className="btn green rounded " style={{ marginTop: 20 }}>
              <b>Create Location</b>
            </button>
          </form>
        </Paper>
      </div>
      {/* </div> */}
    </Container>
  );
};
export default CreateLocation;
