import React, { createContext } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";
import Login from "./Pages/Login";
import UserLogin from "./Pages/UserLogin";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Home from "./Pages/Home";
import UserManagement from "./Pages/UserManagement";
import CreateLocation from "./Pages/Location/CreateLocation";
import EditLocation from "./Pages/Location/EditLocation";
import ViewLocationDetails from "./Pages/Location/ViewLocationDetails";
import LocationManagement from "./Pages/Location/LocationManagement";
import Navbar from "./Components/NavBar/Navbar";
import createCharger from "./Pages/Charger/CreateCharger";
import EditCharger from "./Pages/Charger/EditCharger";
import ViewChargerDetails from "./Pages/Charger/ViewChargerDetails";
import ChargerManagement from "./Pages/Charger/ChargerManagement";
import PricingManagement from "./Pages/ChargerPriceManagement/PricingManagement";
import UpdateChargerPrice from "./Pages/ChargerPriceManagement/UpdateChargerPrice";
import Sidemenu from "./Components/Sidemenu/Sidemenu";
import Drawer from "./Components/Drawer";
import ProtectedRoute from "./Access/ProtectedRoute";
import TransactionManagement from "./Pages/Transactions/TransactionManagement";
import AddOperator from "./Pages/UserManagement/Operator/AddOperator";
import OperatorManagement from "./Pages/UserManagement/Operator/OperatorManagement";
import AddAdmin from "./Pages/UserManagement/AdminManagement/AddAdmin";
import TransactionByCharger from "./Pages/Transactions/TransactionByCharger";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <div className="App">
          {/* <Sidemenu /> */}
          {/* <Drawer /> */}
          <Switch>
            <Route exact path="/adminLogin" component={Login} />
            <Route exact path="/login" component={UserLogin} />
            <Route exact path="/" component={Home} />

            <Route exact path="/Home" component={Home} />

            <ProtectedRoute
              exact
              path="/usermanagement"
              component={UserManagement}
            />
            <ProtectedRoute
              exact
              path="/CreateLocation"
              component={CreateLocation}
            />
            <ProtectedRoute
              exact
              path="/createCharger/:locationId"
              component={createCharger}
            />
            <ProtectedRoute
              exact
              path="/editlocation/:locationId"
              component={EditLocation}
            />
            <ProtectedRoute
              exact
              path="/viewlocation/:locationId"
              component={ViewLocationDetails}
            />
            <ProtectedRoute
              exact
              path="/LocationManagement"
              component={LocationManagement}
            />
            <ProtectedRoute
              exact
              path="/editcharger/:chargeBoxKey"
              component={EditCharger}
            />
            <ProtectedRoute
              exact
              path="/viewChargerDetails/:chargeBoxKey"
              component={ViewChargerDetails}
            />
            <ProtectedRoute
              exact
              path="/ChargerManagement/:locationId"
              component={ChargerManagement}
            />
            <ProtectedRoute
              exact
              path="/updateChargerPrice/:priceId"
              component={UpdateChargerPrice}
            />
            <ProtectedRoute
              exact
              path="/chargerPricing/:locationId"
              component={PricingManagement}
            />
            <ProtectedRoute
              exact
              path="/AddOperator/:locationId"
              component={AddOperator}
            />
            <ProtectedRoute
              exact
              path="/operator/:locationId"
              component={OperatorManagement}
            />
            <ProtectedRoute exact path="/AddAdmin" component={AddAdmin} />

            <ProtectedRoute
              exact
              path="/TransactionManagement"
              component={TransactionManagement}
            />

            <ProtectedRoute
              exact
              path="/transaction/:chargeBoxKey"
              component={TransactionByCharger}
            />
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
