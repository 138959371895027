import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/navbar_logo.png";
import { useUpdateLogin } from "../../Context/LoginContext";
import OtpInput from "../../Components/OtpInput/OtpInput";
// import Loader from "../Components/Loader";
import Loader from "react-loader-spinner";
import { AiFillMobile, AiFillLock } from "react-icons/ai";
import { FaUserAlt, FaLock } from "react-icons/fa";
import "./Login.css";
import { getUser, checkRole } from "../../Access/configAccess";
import { loginApi } from "../../API/methods/authFlow";
import { setUserDataToLocalStorage } from "../../Access/configAccess";
import { validateEmail, validatePassword } from "../../util";
import {
  Container,
  TextField,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";

export default function Login(props) {
  let history = useHistory();
  const [countryCode, setCountryCode] = useState("+91");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const updateLogin = useUpdateLogin();
  const [validationError, setError] = useState({
    email: null,
    password: null,
  });

  function signin(event) {
    event.preventDefault();

    let checkEmail = validateEmail(userId);
    let checkPass = validatePassword(password);
    let errData = {
      email: null,
      password: null,
    };
    if (!checkEmail) errData.email = "Invalid Email";
    if (!checkPass) errData.password = "Minimum 6 character";
    setError(errData);
    if (!checkEmail || !checkPass) return;
    setIsLoading(true);
    const user = {
      username: userId,
      password: password,
      // userType: "ADMIN",
    };
    loginApi(user)
      .then((res) => {
        // console.log("Auth response:  ", res);
        if (res.status === 200) {
          if (!checkRole(res.data.role)) {
            alert("Invalid User Role");
            setIsLoading(false);
            return;
          }
          setUserDataToLocalStorage(res.data);
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          setIsLoading(false);
          updateLogin(true);
          history.push("/Home");
        } else if (res.status === 403) {
          setIsLoading(false);
          alert("Invalid Credentials");
        } else {
          setIsLoading(false);
          alert("Login failed");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  function signup(event) {
    alert("Sorry, registration is not available at this moment.");
  }

  document.documentElement.style.setProperty("--side-nav-width", "0px");
  return (
    // <div className="container login-container">

    <Container maxWidth="xl" disableGutters>
      <div className="login-container">
        <div className="login-box">
          <input type="checkbox" id="flip" />
          <div className="cover">
            <div className="front">
              <img className="evit-logo" src={logo} />
            </div>
            <div className="back">
              <img className="evit-back-logo" src={logo} />
            </div>
          </div>
          {/* <form action="#"> */}
          <div className="form-content">
            <div className="login-form">
              <div className="title">Admin Login</div>
              <div className="input-boxes">
                {/* <div className="input-box">
                <AiFillMobile className="input-icon" />
                <input
                  // className={"form-control form-group"}
                  type="tel"
                  placeholder={"Enter user id"}
                  required
                  value={userId}
                  // maxLength={10}
                  onChange={(event) => setUserId(event.target.value)}
                />
              </div> */}

                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item>
                    <FaUserAlt className="input-icon" />
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <TextField
                      id="email-basic"
                      label="Email Id"
                      // variant="outlined"
                      fullWidth
                      placeholder="Email Id"
                      name="email"
                      value={userId}
                      onChange={(event) => setUserId(event.target.value)}
                      required
                      size="medium"
                      error={validationError.email ? true : false}
                      helperText={validationError.email}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item>
                    <FaLock className="input-icon" />
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <TextField
                      id="password-basic"
                      label="Password"
                      // variant="outlined"
                      fullWidth
                      name="password"
                      type="password"
                      value={password}
                      placeholder="Enter your password"
                      required
                      onChange={(event) => setPassword(event.target.value)}
                      size="medium"
                      error={validationError.password ? true : false}
                      helperText={validationError.password}
                    />
                  </Grid>
                </Grid>

                {/* <div className="input-box">
                <AiFillLock className="input-icon" />
                <input
                  type="password"
                  value={password}
                  placeholder={"Enter your password"}
                  required
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div> */}
                <div className="button input-box">
                  <input type="button" value="Login" onClick={signin} />
                </div>
                {isLoading && (
                  <div className="input-box justify">
                    <Loader
                      type="ThreeDots"
                      color="#26a96c"
                      height={80}
                      width={80}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="signup-form">
            <div className="title">Signup</div>
            <div className="input-boxes">
              <div className="input-box">
                <AiFillMobile className="input-icon" />
                <input
                  // className={"form-control form-group"}
                  type="tel"
                  placeholder={"Enter user id/Mobile number"}
                  required
                  value={userId}
                  onChange={(event) => setUserId(event.target.value)}
                />
              </div>
              <div className="input-box">
                <AiFillLock className="input-icon" />
                <input
                  type="password"
                  value={password}
                  placeholder={"Enter your password"}
                  required
                  onChange={(newPwd) => setPassword(newPwd)}
                />
              </div>
              <div className="button input-box">
                <input type="button" value="Register" onClick={signup} />
              </div>

              {isLoading && (
                <div className="input-box justify">
                  <Loader
                    type="ThreeDots"
                    color="#26a96c"
                    height={80}
                    width={80}
                  />
                </div>
              )}

              <div className="text login-text">
                Already have an account? <label htmlFor="flip">Login now</label>
              </div>
            </div>
          </div> */}
          </div>
          {/* </form> */}
        </div>
      </div>
    </Container>
  );
}
