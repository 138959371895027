import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/navbar_logo.png";
import { useUpdateLogin } from "../../Context/LoginContext";
import OtpInput from "../../Components/OtpInput/OtpInput";
// import Loader from "../Components/Loader";
import Loader from "react-loader-spinner";
import { AiFillMobile, AiFillLock } from "react-icons/ai";
import "../Login/Login.css";

var r = document.querySelector(":root");

export default function Login(props) {
  let history = useHistory();
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateLogin = useUpdateLogin();

  function getOTP(event) {
    setIsLoading(true);
    const endpoint = "https://www.evitonline.com/user/mobile/auth/validateUser";
    const user = {
      countryCode: countryCode,
      mobileNumber: mobileNumber,
      password: "",
    };
    axios
      .post(endpoint, user)
      .then((res) => {
        if (res.status === 200) {
          // console.log("getOTP resp ", res);
          // sessionStorage.setItem("token", res.data.token);
          // history.push("/Home");
          setIsLoading(false);
          setShowOTP(true);
        } else if (res.status === 403) {
          alert("Invalid Credentials");
        }
      })
      .catch((error) => console.log(error))
      .then(() => setIsLoading(false));

    event.preventDefault();
  }

  // r.style.setProperty('--blue', 'lightblue');

  const handleBtnPress = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowOTP(true);
    }, 1500);
  };

  return (
    <div className="container login-container">
      <div className="login-box">
        <input type="checkbox" id="flip" />
        <div className="cover">
          <div className="front">
            <img className="evit-logo" src={logo} />
          </div>
          <div className="back">
            <img className="evit-back-logo" src={logo} />
          </div>
        </div>
        {/* <form action="#"> */}
        <div className="form-content">
          <div className="login-form">
            <div className="title">{showOTP ? "Verification" : "Login"}</div>
            {!showOTP ? (
              <div className="input-boxes">
                <div className="input-box">
                  <AiFillMobile className="input-icon" />
                  <input
                    // className={"form-control form-group"}
                    type="tel"
                    placeholder={"Enter user id/Mobile number"}
                    required
                    value={mobileNumber}
                    // maxLength={10}
                    onChange={(event) => setMobileNumber(event.target.value)}
                  />
                </div>

                <div className="button input-box">
                  <input type="button" value="Get OTP" onClick={getOTP} />
                </div>
                {isLoading && (
                  <div className="input-box justify">
                    <Loader
                      type="ThreeDots"
                      color="#26a96c"
                      height={80}
                      width={80}
                    />
                  </div>
                )}
                <div className="text signup-text">
                  Don't have an account?{" "}
                  <label htmlFor="flip">Signup now</label>
                </div>
              </div>
            ) : (
              <div className="input-boxes">
                <OtpInput
                  user={{ countryCode, mobileNumber, password }}
                  {...props}
                />
              </div>
            )}
          </div>
          <div className="signup-form">
            <div className="title">{showOTP ? "Verification" : "Signup"}</div>
            {!showOTP ? (
              <div className="input-boxes">
                <div className="input-box">
                  <AiFillMobile className="input-icon" />
                  <input
                    // className={"form-control form-group"}
                    type="tel"
                    placeholder={"Enter user id/Mobile number"}
                    required
                    value={mobileNumber}
                    // maxLength={10}
                    required
                    onChange={(event) => setMobileNumber(event.target.value)}
                  />
                </div>
                <div className="input-box">
                  <AiFillLock className="input-icon" />
                  <input
                    type="password"
                    value={password}
                    placeholder={"Enter your password"}
                    required
                    onChange={(newPwd) => setPassword(newPwd)}
                  />
                </div>
                <div className="button input-box">
                  <input type="button" value="Get OTP" onClick={getOTP} />
                </div>

                {isLoading && (
                  <div className="input-box justify">
                    <Loader
                      type="ThreeDots"
                      color="#26a96c"
                      height={80}
                      width={80}
                    />
                  </div>
                )}

                <div className="text login-text">
                  Already have an account?{" "}
                  <label htmlFor="flip">Login now</label>
                </div>
              </div>
            ) : (
              <div className="input-boxes">
                <OtpInput
                  user={{ countryCode, mobileNumber, password }}
                  {...props}
                />
              </div>
            )}
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
