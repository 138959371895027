import React, { useState } from "react";
import { useHistory, Link, NavLink, useParams } from "react-router-dom";
import "../Location//Locations.css";
import {
  TextField,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Container,
} from "@material-ui/core";
import { feedbackAlert, confirmdelete } from "../../Components/Alerts/Alerts";
import {
  createChargerApi,
  getUploadUrl,
  uploadImage,
} from "../../API/methods/chargerFlow";
import LinearProgressWithLabel from "../../Components/LinearProgressWithLabel";

const CreateCharger = () => {
  let history = useHistory();
  const { locationId } = useParams();
  const [Charger, setCharger] = useState({
    model: "",
    vendor: "",
    chargerPointSerialNo: "",
    chargerBoxSerialNo: "",
    firmwareVersion: "",
    iccid: "",
    imsi: "",
    meterType: "",
    metersSerialNo: "",
    chargerPrice: "",
    connectorOne: "",
    connectorOneImage: undefined,
    connectorTwo: "",
    connectorTwoImage: undefined,
  });

  const [selectedImageOne, setselectedImageOne] = useState();
  const [selectedImageTwo, setselectedImageTwo] = useState();
  const [uploadUrlOne, setUploadOneUrl] = useState();
  const [uploadUrlTwo, setUploadTwoUrl] = useState();
  const [isUploadOneComplete, setIsUploadOneComplete] = useState(false);
  const [isUploadTwoComplete, setIsUploadTwoComplete] = useState(false);
  const [progressOne, setProgressOne] = React.useState(0);
  const [progressTwo, setProgressTwo] = React.useState(0);

  const {
    model,
    vendor,
    chargerPointSerialNo,
    chargerBoxSerialNo,
    firmwareVersion,
    iccid,
    imsi,
    meterType,
    metersSerialNo,
    chargerPrice,
    connectorOne,
    connectorOneImage,
    connectorTwo,
    connectorTwoImage,
  } = Charger;

  const onInputChange = (e) => {
    const eventValue = e.target.value;
    const eventName = e.target.name;

    if (eventName === "ImageOne") {
      setselectedImageOne(e.target.files[0]);
    } else if (eventName === "ImageTwo") {
      // console.log("event value: ", e.target.files);
      setselectedImageTwo(e.target.files[0]);
    } else {
      setCharger({ ...Charger, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("inside API:" + Charger);
    createChargerApi(locationId, Charger)
      .then((response) => {
        // console.log("response: --- ", response);
        if (response) {
          feedbackAlert("success", "charger created Successfully", () => {
            history.push(`/ChargerManagement/${locationId}`);
          });
        }
      })
      .catch((error) => alert("Charger create failed"));
  };

  const onUploadProgressOne = (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    // console.log("onUploadProgress", percentCompleted);
    setProgressOne(percentCompleted);
  };

  const onUploadProgressTwo = (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    // console.log("onUploadProgress", percentCompleted);
    setProgressTwo(percentCompleted);
  };

  const uploadImg = async (connectorNumber) => {
    //connectorNumber = "connectorOne" || "connectorTwo"
    let urlData = undefined;
    getUploadUrl()
      .then((response) => {
        urlData = response.data;
        // console.log("URL Data", urlData.uploadURL);
        let connectorImage;
        if (connectorNumber == "connectorOne") {
          setUploadOneUrl(urlData);
          // console.log("selectedImageOne: ", selectedImageOne);
          connectorImage = selectedImageOne;
        }
        if (connectorNumber == "connectorTwo") {
          setUploadTwoUrl(urlData);
          // console.log("selectedImageTwo: ", selectedImageTwo);
          connectorImage = selectedImageTwo;
        }

        uploadImage(
          urlData.uploadURL,
          connectorImage,
          connectorNumber == "connectorOne"
            ? onUploadProgressOne
            : onUploadProgressTwo
        )
          .then((uploadResp) => {
            console.log("UploadResponse: ", uploadResp);
            if (connectorNumber == "connectorOne") {
              setCharger({ ...Charger, connectorOneImage: urlData.getUrl });
              setIsUploadOneComplete(true);
            } else if (connectorNumber == "connectorTwo") {
              setCharger({ ...Charger, connectorTwoImage: urlData.getUrl });
              setIsUploadTwoComplete(true);
            }
          })
          .catch((error) => {
            alert("Upload Url2 Request Failed");
          });
      })
      .catch((error) => {
        alert("Upload Url Request Failed");
      });
  };

  return (
    <Container maxWidth="false" disableGutters>
      {/* <div className="w-150 mx-auto shadow p-5"> */}
      <div
        className="form-container"
        // style={{ background: "rgb(244, 255, 251)" }}
      >
        <Paper style={{ padding: 20 }}>
          <h2 className="text-center mb-4">Charger Details</h2>

          <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="chargerBoxSerialNo-basic"
                  label="Charge Box Serial No."
                  variant="filled"
                  fullWidth
                  name="chargerBoxSerialNo"
                  value={chargerBoxSerialNo}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="chargerPointSerialNo-basic"
                  label="Charge Point Serial No."
                  variant="filled"
                  fullWidth
                  name="chargerPointSerialNo"
                  value={chargerPointSerialNo}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="firmwareVersion-basic"
                  label="Firmware Version"
                  variant="filled"
                  fullWidth
                  name="firmwareVersion"
                  value={firmwareVersion}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="iccid-basic"
                  label="ICCID"
                  variant="filled"
                  fullWidth
                  name="iccid"
                  value={iccid}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="imsi-basic"
                  label="IMSI"
                  variant="filled"
                  fullWidth
                  name="imsi"
                  value={imsi}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="metersSerialNo-basic"
                  label="Meters Serial No."
                  variant="filled"
                  fullWidth
                  name="metersserialno"
                  value={metersSerialNo}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="meterType-basic"
                  label="Meter Type"
                  variant="filled"
                  fullWidth
                  name="meterType"
                  value={meterType}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="model-basic"
                  label="Model"
                  variant="filled"
                  fullWidth
                  name="model"
                  value={model}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="chargerPrice-basic"
                  label="Charger Price"
                  variant="filled"
                  fullWidth
                  name="chargerPrice"
                  value={chargerPrice}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="vendor-basic"
                  label="Vendor"
                  variant="filled"
                  fullWidth
                  name="vendor"
                  value={vendor}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper style={{ padding: 20 }}>
                  <div>ConnectorOne Image</div>
                  <input
                    type="file"
                    id="connectorOneImage-basic"
                    name="ImageOne"
                    onChange={(e) => onInputChange(e)}
                  />
                  <input
                    className="btn green rounded "
                    style={{ marginTop: 20 }}
                    type="button"
                    onClick={() => uploadImg("connectorOne")}
                    value="Upload Image"
                  />
                  {progressOne > 0 && progressOne < 100 && (
                    <LinearProgressWithLabel value={progressOne} />
                  )}

                  <p className="title" style={{ marginTop: 30 }}>
                    Uploaded Image:
                  </p>
                  {isUploadOneComplete && (
                    <img
                      src={uploadUrlOne.getUrl}
                      alt="Uploaded File"
                      height="100"
                      width="100"
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="connectorOne-basic"
                  label="connectorOne"
                  variant="filled"
                  fullWidth
                  name="connectorOne"
                  value={connectorOne}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper style={{ padding: 20 }}>
                  <div>ConnectorTwo Image</div>
                  <input
                    type="file"
                    id="connectorTwoImage-basic"
                    name="ImageTwo"
                    onChange={(e) => onInputChange(e)}
                  />
                  <input
                    className="btn green rounded "
                    style={{ marginTop: 20 }}
                    type="button"
                    onClick={() => uploadImg("connectorTwo")}
                    value="Upload Image"
                  />
                  {progressTwo > 0 && progressTwo < 100 && (
                    <LinearProgressWithLabel value={progressTwo} />
                  )}

                  <p className="title" style={{ marginTop: 30 }}>
                    Uploaded Image:
                  </p>
                  {isUploadTwoComplete && (
                    <img
                      src={uploadUrlTwo.getUrl}
                      alt="Uploaded File"
                      height="100"
                      width="100"
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="connectorTwo-basic"
                  label="connectorTwo"
                  variant="filled"
                  fullWidth
                  name="connectorTwo"
                  value={connectorTwo}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
            </Grid>
            <button
              type="submit"
              className="btn green rounded "
              style={{ marginTop: 20 }}
            >
              <b>Add New Charger</b>
            </button>
          </form>
        </Paper>
      </div>
      {/* </div> */}
    </Container>
  );
};

export default CreateCharger;
