import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";

const columns = [];
const rows = [];

export default function UserManagement() {

  return (
    <div className="container pd-20">
      <h1>User Management</h1>
      <div>
        
      </div>
    </div>
  );
}
