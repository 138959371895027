import { apiCall, apiWithoutBaseCall } from "../../API";
import ApiConstants from "../ApiConstants";
import axios from "axios";

export async function createChargerApi(locationId, data) {
  return apiCall(
    `${ApiConstants.CHARGER.CREATE_CHARGER}/${locationId}`,
    data,
    "POST"
  );
}

export async function getChargerByChargerBoxKeyApi(chargerBoxKey) {
  return apiCall(
    `${ApiConstants.CHARGER.GET_CHARGER_BY_CHARGERBOXKEY}${chargerBoxKey}`,
    null,
    "GET"
  );
}

export async function deleteChargerApi(chargerBoxKey) {
  return apiCall(
    `${ApiConstants.CHARGER.DELETE_CHARGER}${chargerBoxKey}`,
    null,
    "DELETE"
  );
}

export async function updateChargerApi(chargerBoxKey, data) {
  return apiCall(
    `${ApiConstants.CHARGER.UPDATE_CHARGER}${chargerBoxKey}`,
    data,
    "PUT"
  );
}

export async function getUploadUrl() {
  return apiCall(`${ApiConstants.CHARGER.GET_IMAGE_UPLOAD_URL}`, null, "GET");
}

export async function uploadImage(URL, Image, onUploadProgress) {
  let options;
  options = {
    method: "PUT",
    url: URL,
    ...(Image && { data: Image }),
    headers: {
      // Accept: "application/json",
      "Content-Type": `${Image.type}`,
    },
    ...(onUploadProgress && { onUploadProgress: onUploadProgress }),
  };
  console.warn("API options", JSON.stringify(options));
  return axios(options)
    .then((resp) => {
      // console.log("Api response ", resp);
      return resp;
    })
    .catch((error) => {
      console.log("API error response ", error, "resp1 ", error.status);
      alert(error);
    });
}
