import React, { useState } from "react";
import "./AddAdmin.css";
import {
  TextField,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";

function AddAdmin() {
  const [operator, setOperator] = useState({
    role: "",
    company: "",
    email: "",
    password: "",
  });

  const onInputChange = (e) => {
    const eventValue = e.target.value;
    const eventName = e.target.name;
    if (eventName === "active") {
      setOperator({ ...operator, [e.target.name]: !e.target.value });
    } else {
      setOperator({ ...operator, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="container pd-20">
      <Paper style={{ padding: 10 }}>
        <h2 className="text-center mb-4 mt-4">Add Admin</h2>

        <form style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Role-basic"
                label="Role"
                variant="outlined"
                fullWidth
                placeholder="Admin"
                name="Role"
                value={operator.role}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Comapany"
                label="Comapany"
                variant="outlined"
                fullWidth
                type="text"
                placeholder="Comapany"
                name="assignedStation"
                value={operator.company}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="userId-basic"
                label="Email"
                variant="outlined"
                fullWidth
                placeholder="Email"
                name="Email"
                value={operator.email}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="Password-basic"
                label="Password"
                variant="outlined"
                fullWidth
                placeholder="Password"
                name="Password"
                value={operator.password}
                onChange={(e) => onInputChange(e)}
                required
                size="small"
              />
            </Grid>
          </Grid>
          <button className="btn green rounded " style={{ marginTop: 20 }}>
            <b>Save</b>
          </button>
        </form>
      </Paper>
    </div>
  );
}

export default AddAdmin;
