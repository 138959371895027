import React from 'react'
import { useTable, usePagination } from 'react-table'
import './Table.css'

function Table(props) {
  const columns = React.useMemo(
    () => props.columns,
    []
  )

  const data = props.data
  // console.log('Data : ', data)

  const tableInstance = useTable({ columns, data }, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = tableInstance

  const { pageIndex, pageSize } = state


  return (
    <div className="data-table">
      <div className="data-table-action">
      <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
        {
          [10, 20, 30, 50, 100].map(pageSize => (
            <option key={pageSize} value={pageSize}>{pageSize} rows</option>
          ))
        }
      </select>
      </div>

      
      
      <table {...getTableProps()}>
        <thead>
          {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()}>
                      {// Render the header
                        column.render('Header')}
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            page.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {// Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {// Render the cell contents
                            cell.render('Cell')}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
        </tbody>
      </table>
      {(canNextPage || canPreviousPage) && 
        <div className="table-pagination">
        <button className="btn green" onClick={() => gotoPage(0)}> {'<<'} </button>
        <button className="btn green" onClick={() => previousPage()} disabled={!canPreviousPage} >Previous</button>
        <span>Page - {pageIndex + 1} of {pageOptions.length} </span>
        <button className="btn green" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
        <button className="btn green" onClick={() => gotoPage(pageOptions.length -1 )}> {'>>'} </button>
      </div>}

    </div>
  )
}

export default Table
