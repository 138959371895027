import { apiCall } from "../../API";
import ApiConstants from "../ApiConstants";
import { getUserDataFromLocalStorage } from "../../Access/configAccess";

export async function addOperatorToLocationApi(data) {
  return apiCall(`${ApiConstants.USER.ADD_OPERATOR}`, data, "POST");
}

export async function addUserApi(data) {
  return apiCall(`${ApiConstants.USER.ADD_USER}`, data, "POST");
}
//Add User params: Role: "OPERATOR/ADMIN/SUPER_ADMIN"
// "company": "string",
//   "email": "string",
//   "password": "string",
//   "role": "string"
// }

export async function getOperatorByLocationApi(locationId) {
  const URL = `${ApiConstants.USER.GET_OPERATOR_BY_LOCATION}${locationId}`;
  return apiCall(URL, null, "GET");
}

export async function deleteOperatorByEmailApi(email) {
  const URL = `${ApiConstants.USER.DELETE_OPERATOR}${email}`;
  return apiCall(URL, null, "GET");
}
