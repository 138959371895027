import { apiCall, apiWithoutBaseCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function loginApi(data) {
  return apiCall(ApiConstants.AUTH.LOGIN, data, "POST");
}

export async function otpLoginApi(data) {
  return apiWithoutBaseCall(ApiConstants.AUTH.MOBILE_LOGIN, data, "POST");
}

export async function logoutApi() {
  return apiCall(ApiConstants.AUTH.LOGOUT, null, "POST");
}
