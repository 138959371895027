import ApiConstants from "./ApiConstants";
import axios from "axios";

export function apiCall(path, params, method) {
  const token = localStorage.getItem("token");
  let options;
  options = {
    method: method,
    url: ApiConstants.BASE_URL + path,
    ...(params && { data: params }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
  return axios(options)
    .then((resp) => {
      // console.log("API response ", resp);
      return resp;
    })
    .catch((error) => {
      if (error.response.status === 403) {
        localStorage.clear();
        sessionStorage.clear();
        return;
      }
      console.log("API error response ", error, "resp ", error.response);
      alert(error);
    });
}

export function apiWithoutBaseCall(path, params, method) {
  const token = localStorage.getItem("token");
  let options;
  options = {
    method: method,
    url: path,
    ...(params && { data: params }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
  // console.log(JSON.stringify(path));
  return axios(options)
    .then((resp) => {
      // console.log("Api response ", resp);
      return resp;
    })
    .catch((error) => {
      console.log("API error response ", error, "resp1 ", error.status);
      alert(error);
    });
}
