import React, { useState } from "react";
import "./Marker.css";
import Tooltip from "../Tooltip";
import * as MdIcons from "react-icons/md";

export default function Marker({ name, onPress, backgroundColor, data }) {
  return (
    <Tooltip
      content={
        <div className="tooltip-card">
          {data && (
            <div className="station-name">
              <MdIcons.MdEvStation className="station-icon" />
              {data.description}
            </div>
          )}
          {data && (
            <div className="station-add">
              {`${data.street}, ${data.landmark}, ${data.city}, ${data.country}`}
            </div>
          )}
        </div>
      }
    >
      <div>
        <div
          className="pin bounce"
          style={{ cursor: "pointer", backgroundColor: backgroundColor }}
          // title={name}
          onClick={onPress}
        />
        <div className="pulse" />
      </div>
    </Tooltip>
  );
}
