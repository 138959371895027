import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import Table from "../../Components/Table/Table";
import { getPricingFromLocationIDApi } from "../../API/methods/priceFlow";
import { USER, getRolefromLocal } from "../../Access/configAccess";
import { Paper, Container } from "@material-ui/core";

function PricingManagement() {
  const access = getRolefromLocal();
  const [Prices, setPrice] = useState([]);
  const { locationId } = useParams();
  const { priceId } = useParams();
  useEffect(() => {
    loadPrices();
  }, []);

  const loadPrices = async () => {
    getPricingFromLocationIDApi(locationId)
      .then((resp) => {
        // console.log("Load price data : ", resp.data);
        setPrice(resp.data);
      })
      .catch((error) => {
        alert("Request failed");
      });
  };

  const columns = [
    {
      Header: "LocationId",
      accessor: "locationId",
    },
    {
      Header: "Price Id",
      accessor: "priceId",
    },
    {
      Header: "Meter Type",
      accessor: "meterType",
    },
    {
      Header: "Charger Price per minute",
      accessor: "chargerPrice",
    },
    {
      id: "action",
      Header: "Action",
      accessor: "id",
      Cell: ({ cell }) => (
        <div>
          <Link
            className="btn info-transparent icon-l rounded"
            to={`/updateChargerPrice/${cell.row.original.priceId}`}
          >
            <AiIcons.AiFillEdit />
          </Link>
        </div>
      ),
    },
  ];

  const columnsSuper = [
    {
      Header: "LocationId",
      accessor: "locationId",
    },
    {
      Header: "Price Id",
      accessor: "priceId",
    },
    {
      Header: "Meter Type",
      accessor: "meterType",
    },
    {
      Header: "Charger Price per minute",
      accessor: "chargerPrice",
    },
  ];

  return (
    <Container maxWidth='false' disableGutters className="pt-4">
      <h2>Charger Price</h2>

      <div className="tables">
        {Prices.length > 0 && (
          <Paper>
            <Table
              columns={access === USER.ADMIN ? columns : columnsSuper}
              data={Prices}
            />
          </Paper>
        )}
      </div>
    </Container>
  );
}

export default PricingManagement;
