import React from "react";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import { USER, getRolefromLocal } from "../../Access/configAccess";

export const getSidebarData = () => {
  const access = getRolefromLocal();
  if (access === null) return [];
  switch (access.toUpperCase()) {
    case USER.SUPERADMIN:
      return SidebarDataSuperAdmin;
    case USER.ADMIN:
      return SidebarDataAdmin;
    case USER.OPERATOR:
      return SidebarDataOperator;

    default:
      return [];
  }
};

const ICON_SIZE = 25;

export const SidebarDataAdmin = [
  {
    title: "Home",
    path: "/Home",
    icon: <AiIcons.AiOutlineHome size={ICON_SIZE}/>,
    cName: "nav-link",
  },
  {
    title: "Locations",
    path: "/LocationManagement",
    icon: <MdIcons.MdLocationOn size={ICON_SIZE} />,
    cName: "nav-link",
  },
  // {
  //   title: "Charger",
  //   path: "/ChargerManagement",
  //   icon: <MdIcons.MdEvStation size={ICON_SIZE}/>,
  //   cName: "nav-link",
  // },
  // {
  //   title: "Price",
  //   path: "/PricingManagement",
  //   // icon: <MdIcons.MdAttachMoney size={ICON_SIZE} />,
  //   icon: <BiIcons.BiRupee size={ICON_SIZE} />,
  //   cName: "nav-link",
  // },
  {
    title: "Transactions",
    path: "/TransactionManagement",
    icon: <MdIcons.MdTerrain size={ICON_SIZE} />,
    cName: "nav-link",
  },
];

export const SidebarDataOperator = [
  {
    title: "Home",
    path: "/Home",
    icon: <AiIcons.AiOutlineHome size={ICON_SIZE} />,
    cName: "nav-link",
  },
  {
    title: "Locations",
    path: "/LocationManagement",
    icon: <MdIcons.MdLocationOn size={ICON_SIZE} />,
    cName: "nav-link",
  },
  // {
  //   title: "Charger",
  //   path: "/ChargerManagement",
  //   icon: <MdIcons.MdEvStation size={ICON_SIZE} />,
  //   cName: "nav-link",
  // },
];

export const SidebarDataSuperAdmin = [
  {
    title: "Home",
    path: "/Home",
    icon: <AiIcons.AiOutlineHome size={ICON_SIZE} />,
    cName: "nav-link",
  },
  {
    title: "Locations",
    path: "/LocationManagement",
    icon: <MdIcons.MdLocationOn size={ICON_SIZE} />,
    cName: "nav-link",
  },
  // {
  //   title: "Charger",
  //   path: "/ChargerManagement",
  //   icon: <MdIcons.MdEvStation size={ICON_SIZE} />,
  //   cName: "nav-link",
  // },
];
