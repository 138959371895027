import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  getPriceByPriceIdApi,
  updatePricingApi,
} from "../../API/methods/priceFlow";
import { Container, Paper, Grid, TextField } from "@material-ui/core";
import Card from "../../Components/Card";

const UpdateChargerPrice = () => {
  let history = useHistory();
  const { locationId } = useParams();
  const { priceId } = useParams();
  const [Price, setPrice] = useState({
    locationId: "",
    priceId: "",
    meterType: "",
    chargerPrice: "",
    lastModifiedBy: "",
  });

  const { meterType, chargerPrice } = Price;
  useEffect(() => {
    loadPrices();
  }, []);
  const loadPrices = async () => {
    getPriceByPriceIdApi(priceId)
      .then((response) => {
        // console.log('getPriceByPriceIdApi resp: ',response.data)
        setPrice(response.data);
      })
      .catch((error) => {
        alert("Failed request");
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let payLoad = {
      meterType: Price.meterType,
      chargerPrice: Price.chargerPrice,
    };
    updatePricingApi(priceId, payLoad)
      .then((response) => {
        setPrice(response.data);
        if (response.status == 200) {
          alert("price updated successfully");
        }
      })
      .catch((error) => {
        alert("Error occurred while updating Price ");
      })
      .then(() => history.push("/LocationManagement"));
  };

  const onInputChange = (e) => {
    if (e.target.name === "status") {
      Location.active = e.target.checked;
      // console.log(Location);
    }
    setPrice({ ...Price, [e.target.name]: e.target.value });
  };
  function onPriceChange(event) {
    const eventValue = event.target.value;
    const eventName = event.target.name;
    setPrice((prevState) => {
      if ("meterType" === eventName) {
        Price.meterType = eventValue;
      } else if ("chargerPrice" === eventName) {
        Price.chargerPrice = eventValue;
      } else if ("priceId" === eventName) {
        Price.priceId = eventValue;
      } else if ("lastModifiedBy" === eventName) {
        Price.lastModifiedBy = eventValue;
      } else if ("latitude" === eventName) {
        Location.latitude = eventValue;
      }
      return {
        ...prevState,
        eventName: eventValue,
      };
    });
  }

  return (
    <Container maxWidth="false" disableGutters className="pt-4">
      <div className="form-container">
        <Paper style={{ padding: 20 }}>
          <h2 className="text-center mb-4">Pricing Update</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="priceId-basic"
                  label="Price Id"
                  variant="filled"
                  fullWidth
                  name="priceId"
                  value={priceId}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="meterType-basic"
                  label="Meter Type"
                  variant="filled"
                  fullWidth
                  name="meterType"
                  value={meterType}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="chargerPrice-basic"
                  label="Charger Price"
                  variant="filled"
                  fullWidth
                  name="chargerPrice"
                  value={chargerPrice}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
            </Grid>

            <button class="btn green rounded mt-4">
              <b>Update</b>
            </button>
          </form>
        </Paper>
      </div>
    </Container>
  );
};

export default UpdateChargerPrice;
