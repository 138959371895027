import React, { useState, useEffect } from "react";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { getSidebarData } from "./SidemenuData";
import "./Sidemenu.css";
import { useLocation } from "react-router";
import { useLogin } from "../../Context/LoginContext";

function Sidemenu() {
  const [sidebar, setSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showSidebar = () => setSidebar(!sidebar);
  const location = useLocation();
  const WIDTH = window.innerWidth;
  const isLogin = useLogin();
  const SIDEBARDATA = getSidebarData();

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <>
      {location.pathname === "/adminLogin" ||
      location.pathname === "/login" ||
      location.pathname === "/Logout" ||
      (location.pathname === "/" && !isLogin) ||
      (location.pathname === "/Home" && !isLogin) ? (
        <>
          {document.documentElement.style.setProperty(
            "--side-nav-width",
            "0px"
          )}
        </>
      ) : (
        <>
          {windowWidth <= 730
            ? document.documentElement.style.setProperty(
                "--side-nav-width",
                "50px"
              )
            : document.documentElement.style.setProperty(
                "--side-nav-width",
                "250px"
              )}
          <nav className={sidebar ? "sidebar active " : "sidebar"}>
            <ul>
              {/* <li>
              <Link to="#" className="nav-logo">
                <img src={logo} />
              </Link>
            </li> */}
              <li className="margin-top" />

              {SIDEBARDATA.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className={
                        item.path === location.pathname
                          ? `${item.cName} active`
                          : item.cName
                      }
                    >
                      <div>{item.icon}</div>
                      {/* {windowWidth >= 730 && <span className='nav-title'>{item.title}</span>} */}
                      <span className="nav-title">{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </>
      )}
    </>
  );
}

export default Sidemenu;
