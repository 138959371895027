import { apiCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function getPricingFromLocationIDApi(locationId) {
  return apiCall(
    `${ApiConstants.PRICING.GET_PRICING_BY_LOCATIONID}${locationId}`,
    null,
    "GET"
  );
}

export async function getPriceByPriceIdApi(priceId) {
  return apiCall(
    `${ApiConstants.PRICING.GET_PRICING_BY_PRICEID}${priceId}`,
    null,
    "GET"
  );
}

export async function updatePricingApi(priceId, data) {
  return apiCall(
    `${ApiConstants.PRICING.UPDATE_PRICING}${priceId}`,
    data,
    "PUT"
  );
}
