import React, { useState } from "react";
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Divider,
  Toolbar,
  ListItemText,
} from "@material-ui/core";
import { getSidebarData } from "../Sidemenu/SidemenuData";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useLogin } from "../../Context/LoginContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
}));

function Drawer() {
  const classes = useStyles();
  const SIDEBARDATA = getSidebarData();
  const location = useLocation();
  const isLogin = useLogin();

  return (
    <>
      {location.pathname === "/adminLogin" ||
      location.pathname === "/login" ||
      (location.pathname === "/" && !isLogin) ||
      (location.pathname === "/Home" && !isLogin) ? (
        <>
          {document.documentElement.style.setProperty(
            "--side-nav-width",
            "0px"
          )}
        </>
      ) : (
        <MUIDrawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {SIDEBARDATA.map((item, index) => {
                const { title, path, icon } = item;
                return (
                  <Link
                    to={item.path}
                    key={index}
                    className={
                      item.path === location.pathname
                        ? `${item.cName} active`
                        : item.cName
                    }
                  >
                    <ListItem
                      button
                      key={title}
                      // selected={item.path === location.pathname}
                    >
                      {/* <ListItemIcon> */}
                      {icon}
                      {/* </ListItemIcon> */}
                      <ListItemText primary={title} />
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </div>
        </MUIDrawer>
      )}
    </>
  );
}

export default Drawer;
