import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import Table from "../../../Components/Table/Table";
import { USER, getRolefromLocal } from "../../../Access/configAccess";
import {
  getOperatorByLocationApi,
  deleteOperatorByEmailApi,
} from "../../../API/methods/userFlow";
import {
  confirmdelete,
  feedbackAlert,
} from "../../../Components/Alerts/Alerts";
import { Paper, Container } from "@material-ui/core";

function OperatorManagement() {
  const history = useHistory();
  const { locationId } = useParams();
  const [operator, setOperator] = useState([]);

  useEffect(() => {
    loadOperator();
  }, []);
  const access = getRolefromLocal();

  const loadOperator = async () => {
    getOperatorByLocationApi(locationId)
      .then((response) => {
        if (response) {
          // console.log("loadOperator Data", response.data);
          setOperator(response.data.allOperators);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Operator Id",
      accessor: "operatorId",
    },
    {
      id: "action",
      Header: "Action",
      accessor: "id",
      Cell: ({ cell }) => (
        <div>
          <button
            className="btn info-transparent icon-l rounded"
            onClick={() => {
              confirmdelete(
                "Confirmation",
                "Are you sure you want to delete this location",
                () => {
                  deleteOperatorByEmailApi(cell.row.values.email)
                    .then((response) =>
                      feedbackAlert("success", "Charger Deleted", () => {
                        history.push(`/operator/${locationId}`);
                      })
                    )
                    .catch((erroe) =>
                      feedbackAlert(
                        "error",
                        "Error occurred while deleting Operator",
                        () => {
                          history.push(`/operator/${locationId}`);
                        }
                      )
                    );
                }
              );
            }}
          >
            <MdIcons.MdDelete />
          </button>
        </div>
      ),
    },
  ];

  const columnsSuper = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Operator Id",
      accessor: "operatorId",
    },
  ];

  return (
    <Container maxWidth="false" disableGutters className="pt-4 pr-2">
      <div className="tables">
        <h2 className="mb-4" style={{ marginBottom: 5, marginTop: 5 }}>
          Operators
        </h2>
        {access === USER.ADMIN && (
          <Link className="btn green icon-l" to={`/AddOperator/${locationId}`}>
            <AiIcons.AiOutlinePlusCircle />
            Add New Operator
          </Link>
        )}
        {operator && operator.length > 0 && (
          <Paper>
            <Table
              columns={access === USER.ADMIN ? columns : columnsSuper}
              data={operator}
            />
          </Paper>
        )}
      </div>
    </Container>
  );
}

export default OperatorManagement;
