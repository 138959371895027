import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import Table from "../../../src/Components/Table/Table";
import { getAllTransactionApi } from "../../API/methods/transactionsFlow";
import { Paper, Container } from "@material-ui/core";

const TransactionManagement = () => {
  const [Transactions, setTransactions] = useState([]);
  useEffect(() => {
    loadTransactions();
  }, []);

  const loadTransactions = async () => {
    getAllTransactionApi()
      .then((resp) => {
        setTransactions(resp.data);
      })
      .catch((error) => {
        alert("Transaction request failed.");
      });
  };

  const columns = [
    {
      Header: "Chargeboxkey",
      accessor: "chargeboxkey",
    },
    {
      Header: "Source ",
      accessor: "source",
    },
    {
      Header: "Destination",
      accessor: "destination",
    },
    {
      Header: "Messageid",
      accessor: "messageid",
    },
    {
      Header: "Sessionid",
      accessor: "sessionid",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "CreationTime",
      accessor: "creationtime",
    },
    {
      Header: "MessageType",
      accessor: "messagetype",
    },

    {
      id: "action",
      Header: "Action",
      accessor: "id",
      Cell: ({ cell }) => (
        <div>
          <Link
            className="btn info-transparent icon-l rounded"
            to={`/viewlocation/${cell.row.original.id}`}
          >
            <AiIcons.AiOutlineEye />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Container maxWidth="false" disableGutters>
      <div className="tables">
        {Transactions.length > 0 && (
          <Paper>
            <Table columns={columns} data={Transactions} />
          </Paper>
        )}
      </div>
    </Container>
  );
};
export default TransactionManagement;
