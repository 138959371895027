import React, { Component } from "react";
import * as AiIcons from "react-icons/ai";
import Loader from "react-loader-spinner";
import ReactOtpInput from "react-otp-input";
import "./OtpInput.css";
import { useUpdateLogin } from "../../Context/LoginContext";
import { otpLoginApi } from "../../API/methods/authFlow";

class OtpInput extends Component {
  static loginUpdate = useUpdateLogin;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      //   otp5: "",
      otp: "",
      disable: true,
      isOTPVisible: false,
      isLoading: false,
      user: undefined,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleChange(value1, event) {
  //   if (this.checkNum(event.target.value)) {
  //     console.log("good value ... ", event.target.value);
  //     this.setState({ [value1]: event.target.value });
  //   } else {
  //     this.setState({ [value1]: "" });
  //   }
  //   console.log("handleChange value ... ", event.target.value);
  // }

  handleChange(changedOtp) {
    this.setState({ otp: changedOtp });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    // console.log(this.state, data);
    this.authenticateUser(event);
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
    const user = this.context;

    // console.log(user);
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      // console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  authenticateUser(event) {
    this.setState({ isLoading: true });
    const user = {
      countryCode: this.state.user.countryCode,
      mobileNumber: this.state.user.mobileNumber,
      otp: this.state.otp,
    };
    // console.log("payload ...", user);

    otpLoginApi(user)
      .then((res) => {
        if (res.status === 200) {
          // console.log("authenticateUser resp ", res);
          // console.log("props resp ", this.props);
          localStorage.setItem("token", res.data.token);
          this.props.history.push("/Home");
          // setIsLoading(false);
        } else if (res.status === 403) {
          alert("Invalid Credentials");
        }
      })
      .catch((error) => console.log(error))
      .then(() => this.setState({ isLoading: false }));

    event.preventDefault();
  }

  checkNum = (event) => {
    const charCode = event.charCodeAt(0);
    // console.log("event Ascii", charCode);
    return charCode >= 48 && charCode <= 57;
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="otp-container">
        <div className="text">
          We've sms you a 4 digit code. Please check your sms and enter the code
          here to complete verification.
        </div>

        <div className="otp-input-container">
          {/* <input
            name="otp1"
            required
            type={this.state.isOTPVisible ? "text" : "password"}
            autoComplete="off"
            className="otpInput"
            value={this.state.otp1}
            onChange={(e) => this.handleChange("otp1", e)}
            tabIndex="1"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
          />
          <input
            name="otp2"
            required
            type={this.state.isOTPVisible ? "text" : "password"}
            autoComplete="off"
            className="otpInput"
            value={this.state.otp2}
            onChange={(e) => this.handleChange("otp2", e)}
            tabIndex="2"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
          />
          <input
            name="otp3"
            required
            type={this.state.isOTPVisible ? "text" : "password"}
            autoComplete="off"
            className="otpInput"
            value={this.state.otp3}
            onChange={(e) => this.handleChange("otp3", e)}
            tabIndex="3"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
          />
          <input
            name="otp4"
            required
            type={this.state.isOTPVisible ? "text" : "password"}
            autoComplete="off"
            className="otpInput"
            value={this.state.otp4}
            onChange={(e) => this.handleChange("otp4", e)}
            tabIndex="4"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
          />

          <input
            name="otp5"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp5}
            onChange={(e) => this.handleChange("otp5", e)}
            tabIndex="5"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
          />*/}

          <ReactOtpInput
            inputStyle="inputStyle"
            isInputNum
            isInputSecure={!this.state.isOTPVisible}
            shouldAutoFocus={true}
            value={this.state.otp}
            onChange={this.handleChange}
            numInputs={4}
            // separator={<span> </span>}
          />

          {this.state.isOTPVisible ? (
            <AiIcons.AiFillEye
              size={25}
              style={{ marginLeft: 10 }}
              onClick={() => this.setState({ isOTPVisible: false })}
            />
          ) : (
            <AiIcons.AiFillEyeInvisible
              size={25}
              style={{ marginLeft: 10 }}
              onClick={() => this.setState({ isOTPVisible: true })}
            />
          )}
        </div>

        <div className="text otp-text">
          Didn't get code yet? <label htmlFor="flip">Resend OTP</label>
        </div>

        <div className="OtpButton">
          <input type="submit" value="Verify" className="button" />
        </div>

        <div className="input-box justify">
          <Loader
            visible={this.state.isLoading}
            type="ThreeDots"
            color="#26a96c"
            height={80}
            width={80}
          />
        </div>
      </form>
    );
  }
}

export default OtpInput;
