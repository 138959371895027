import { apiCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function getAllStationFromLocationIDApi(locationId) {
  return apiCall(
    `${ApiConstants.STATION.GET_ALL_CHARGERS}/${locationId}`,
    null,
    "GET"
  );
}
