import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLogin } from "../Context/LoginContext";
import Drawer from "../Components/Drawer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
}));

function ProtectedRoute({ isAuth: isAuth, component: Component, ...rest }) {
  const isLogin = useLogin();
  const access = localStorage.getItem("access");
  const classes = useStyles();

  // console.log("isLogin...", isLogin);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogin) {
          return (
            <main className={classes.content}>
              {document.documentElement.style.setProperty(
                "--side-nav-width",
                "250px"
              )}
              <Drawer />
              <Component />
            </main>
          );
        } else {
          document.documentElement.style.setProperty("--side-nav-width", "0px");
          return (
            <>
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            </>
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
