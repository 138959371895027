import { apiCall } from "../../API";
import ApiConstants from "../ApiConstants";
import {getUserDataFromLocalStorage} from '../../Access/configAccess'

export async function getAllLocationsApi() {
  return apiCall(ApiConstants.LOCATION.GET_LOCATION, null, "GET");
}

export async function getAllLocationsByCompanyApi() {
  let user = getUserDataFromLocalStorage()
  // console.log("USER:  ....  ", user);
  const URL = `${ApiConstants.LOCATION.GET_LOCATION_BY_COMPANY}${user.company}`;
  // console.log("URL:  ....  ", URL);
  return apiCall(URL, null, "GET");
}

export async function createLocationApi(data) {
  return apiCall(ApiConstants.LOCATION.CREATE_LOCATION, data, "POST");
}

export async function getLocationByLocationIdApi(locationId) {
  return apiCall(
    `${ApiConstants.LOCATION.GET_LOCATION_BY_LOCATIONID}${locationId}`,
    null,
    "GET"
  );
}

export async function deleteLocationApi(locationId) {
  return apiCall(
    `${ApiConstants.LOCATION.DELETE_LOCATION}${locationId}`,
    null,
    "DELETE"
  );
}

export async function updateLocationApi(locationId, data) {
  return apiCall(
    `${ApiConstants.LOCATION.UPDATE_LOCATION}${locationId}`,
    data,
    "PUT"
  );
}
