import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { getChargerByChargerBoxKeyApi } from "../../API/methods/chargerFlow";
import { Container, Paper, Grid } from "@material-ui/core";
import Card from "../../Components/Card";

const ViewChargerDetails = () => {
  const [Charger, setCharger] = useState({
    chargerBoxSerialNo: "",
    chargerId: "",
    chargerPointSerialNo: "",
    description: "",
    diagnosticsStatus: "",
    diagnosticsTimestamp: "",
    endAddress: "",
    firmwareUpdateStatus: "",
    firmwareUpdatedTimestamp: "",
    firmwareVersion: "",
    iccid: "",
    imsi: "",
    insertConnectorStatusAfterTransactionMsg: "",
    lastHeartBeatTimestamp: "",
    metersSerialNo: "",
    meterType: "",
    model: "",
    note: "",
    ocppProtocol: "",
    status: "",
    vendor: "",
    connectoroneimage: undefined,
    connectortwoimage: undefined,
    connectorone: "",
    connectortwo: "",
  });

  const {
    vendor,
    model,
    key,
    locationid,
    street,
    city,
    country,
    zip,
    landmark,
    latitude,
    longitude,
    chargerboxserialno,
    chargerid,
    chargerpointserialno,
    diagnosticstatus,
    diagnosticstimestamp,
    endAddress,
    firmwareupdatestatus,
    firmwareupdatedtimestamp,
    firmwareversion,
    iccid,
    imsi,
    lastHeartbeattimestamp,
    metersserialno,
    metertype,
    note,
    ocppprotocol,
    status,
    description,
    heartbeatinterval,
    connectoroneimage,
    connectortwoimage,
    connectorone,
    connectortwo,
  } = Charger;

  const { chargeBoxKey } = useParams();

  useEffect(() => {
    loadChargers();
  }, []);

  const loadChargers = async () => {
    getChargerByChargerBoxKeyApi(chargeBoxKey)
      .then((response) => {
        // console.log("Charger Dtail : ", response);
        setCharger(response.data);
      })
      .catch((error) => {
        alert("Request Failed");
      });
  };

  return (
    <Container maxWidth="false" disableGutters>
      <Paper style={{ padding: 20 }}>
        <h2 className="mb-5">Charger</h2>

        <Grid container spacing={3}>
          <Card Title={"Vendor"} Data={vendor} />
          <Card Title={"Model"} Data={model} />
          <Card
            Title={"Charger Box Serial No."}
            bigTitle
            Data={chargerboxserialno}
          />
          <Card Title={"Charger Id"} Data={chargerid} />
          <Card
            Title={"Charger Point Serial No."}
            bigTitle
            Data={chargerpointserialno}
          />
          <Card Title={"Description"} Data={description} />
          <Card Title={"Diagnostics Status"} bigTitle Data={diagnosticstatus} />
          <Card
            Title={"Diagnostics Timestamp"}
            bigTitle
            Data={diagnosticstimestamp}
          />
          <Card Title={"End Address"} Data={endAddress} />
          <Card
            Title={"Firmware Update Status"}
            bigTitle
            Data={firmwareupdatestatus}
          />
          <Card
            Title={"Firmware Updated Timestamp"}
            bigTitle
            Data={firmwareupdatedtimestamp}
          />
          <Card Title={"Firmware Version"} bigTitle Data={firmwareversion} />
          <Card Title={"ICCID"} Data={iccid} />
          <Card Title={"IMIS"} Data={imsi} />
          <Card Title={"heartbeatinterval"} bigTitle Data={heartbeatinterval} />
          <Card
            Title={"Last Heart Beat Timestamp"}
            bigTitle
            Data={lastHeartbeattimestamp}
          />
          <Card Title={"Meter Serial No."} Data={metersserialno} />
          <Card Title={"Meter Type"} Data={metertype} />
          <Card Title={"Note"} Data={note} />
          <Card Title={"Ocpp Protocol"} bigTitle Data={ocppprotocol} />
          <Card Title={"Status"} Data={status} />
          <Card Title={"Connector One"} Data={connectorone} />
          <Card Title={"connector Two"} Data={connectortwo} />

          <Grid item xs={12} sm={6}>
            {connectoroneimage && (
              <Paper style={{ padding: 20 }}>
                <div>ConnectorOne Image</div>
                <img
                  src={connectoroneimage}
                  alt="Uploaded File"
                  height="100"
                  width="100"
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {connectortwoimage && (
              <Paper style={{ padding: 20 }}>
                <div>ConnectorTwo Image</div>
                <img
                  src={connectortwoimage}
                  alt="Uploaded File"
                  height="100"
                  width="100"
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ViewChargerDetails;
