import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import Table from "../../../src/Components/Table/Table";
import { getTransactionByChargerApi } from "../../API/methods/transactionsFlow";
import { Paper, Container } from "@material-ui/core";

const TransactionByCharger = () => {
  const [Transactions, setTransactions] = useState([]);
  const { chargeBoxKey } = useParams();
  useEffect(() => {
    loadTransactions();
  }, []);

  const loadTransactions = async () => {
    getTransactionByChargerApi(chargeBoxKey)
      .then((resp) => {
        setTransactions(resp.data);
      })
      .catch((error) => {
        alert("Transaction request failed. ");
      });
  };

  const columns = [
    {
      Header: "Transactions",
      accessor: "id",
      Cell: ({ cell }) => {
        // console.log(cell.row.original);
        return <div>{JSON.stringify(cell.row.original)}</div>;
      },
    },
  ];

  return (
    <Container maxWidth="false" disableGutters>
      <div className="tables mt-4 mr-2">
        {Transactions.length > 0 && (
          <Paper style={{ padding: 20, marginTop: 60 }}>
            <h2 className="text-center mb-4">Charger Transactions</h2>
            <Table columns={columns} data={Transactions} />
          </Paper>
        )}
      </div>
    </Container>
  );
};
export default TransactionByCharger;
