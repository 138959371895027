import React from "react";
import Loader1 from "react-loader-spinner";
import './Loader.css'

class Loader extends React.Component {
  //other logic
  render() {
    return (
      <div className='loader-box'>
        <Loader1
          type="Grid"
          // color="#00BFFF"
          height={100}
          width={100}
          color="#2bc87fa1"
          // timeout={10000} //3 secs
        />
      </div>
    );
  }
}

export default Loader;
