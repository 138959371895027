import React from 'react'
import Modal from 'react-modal'

function ConfirmAlert(props) {
    return (
        <div className="confirm-modal custom-modal">
          
            <div className="heading">
                {props.heading}

            </div>
            <div className="body">
                <p>{props.message}</p>

                {props.children}

            </div>

            <div className="footer">
                <button className="btn green" onClick={props.yes}>Yes</button>
                <button className="btn red" onClick={props.no}>No</button>
            </div>

        </div>
    )
}

export default ConfirmAlert
