import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Locations.css";
import { feedbackAlert, confirmdelete } from "../../Components/Alerts/Alerts";
import {
  getLocationByLocationIdApi,
  updateLocationApi,
  deleteLocationApi,
} from "../../API/methods/locationFlow";
import {
  TextField,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Container,
} from "@material-ui/core";
import { countryList } from "./CountryList";

const EditLocation = () => {
  let history = useHistory();
  const { locationId } = useParams();
  const [Location, setlocation] = useState({
    city: "",
    country: "",
    description: "",
    landmark: "",
    latitude: "",
    longitude: "",
    street: "",
    zip: "",
    company: "",
  });

  const {
    city,
    country,
    description,
    landmark,
    latitude,
    longitude,
    street,
    zip,
    company,
  } = Location;

  useEffect(() => {
    loadLocation();
  }, []);
  const loadLocation = async () => {
    getLocationByLocationIdApi(locationId)
      .then((response) => setlocation(response.data))
      .catch((error) => {
        alert("Request Failed");
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLocationApi(locationId, Location)
      .then((response) => {
        setlocation(response.data);
        if (response.status == 200) {
          feedbackAlert("success", "Location updated sucessfully", () => {
            history.push("/LocationManagement");
          });
        } else {
          feedbackAlert("error", "Error ocuured while uploading", () => {
            history.push("/LocationManagement");
          });
        }
        history.push("/LocationManagement");
      })
      .catch((error) =>
        alert("Error occurred while updating Location Details ")
      );
  };

  const deleteLocation = async (name) => {
    // window.confirm("Are you sure you want to delete this location?");
    deleteLocationApi(locationId)
      .then((response) => {
        if (response.status == 200) {
          history.push("/LocationManagement");
        } else {
          feedbackAlert("error", "Error ocuured while Deleting", () => {
            history.push("/LocationManagement");
          });
        }
      })
      .catch((error) => {
        feedbackAlert("error", "Error ocuured while Deleting", () => {
          history.push("/LocationManagement");
        });
      });
  };

  const onInputChange = (e) => {
    // console.log(e);
    const eventValue = e.target.value;
    const eventName = e.target.name;
    if (eventName === "active") {
      setlocation({ ...Location, [e.target.name]: !e.target.value });
    } else {
      setlocation({ ...Location, [e.target.name]: e.target.value });
    }
  };

  function onLocationChange(event) {
    const eventValue = event.target.value;
    const eventName = event.target.name;
    setlocation((prevState) => {
      if ("city" === eventName) {
        Location.city = eventValue;
      } else if ("country" === eventName) {
        Location.country = eventValue;
      } else if ("description" === eventName) {
        Location.description = eventValue;
      } else if ("landmark" === eventName) {
        Location.landmark = eventValue;
      } else if ("latitude" === eventName) {
        Location.latitude = eventValue;
      } else if ("longitude" === eventName) {
        Location.longitude = eventValue;
      } else if ("street" === eventName) {
        Location.street = eventValue;
      } else if ("zip" === eventName) {
        Location.zip = eventValue;
      } else if ("code" == eventName) {
        Location.zip = eventValue;
      } else if ("company" == eventName) {
        Location.zip = eventValue;
      }
      return {
        ...prevState,
        eventName: eventValue,
      };
    });
  }

  const handleChange = (event) => {
    setlocation({ ...Location, country: event.target.value });
  };

  return (
    <Container maxWidth="false" disableGutters>
      <div className="form-container">
        <Paper style={{ padding: 20 }}>
          <h2 className="text-center mb-4">Location Details</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="Description-basic"
                  label="Description"
                  variant="filled"
                  fullWidth
                  placeholder="Description"
                  name="description"
                  value={description}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Street-basic"
                  label="Street"
                  variant="filled"
                  fullWidth
                  placeholder="Street"
                  name="street"
                  value={street}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Landmark-basic"
                  label="Landmark"
                  variant="filled"
                  fullWidth
                  placeholder="Landmark"
                  name="landmark"
                  value={landmark}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="City"
                  label="City"
                  variant="filled"
                  fullWidth
                  type="text"
                  placeholder="City"
                  name="city"
                  value={city}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  item
                  xs={12}
                  sm={6}
                  size="small"
                >
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Country"
                    onChange={handleChange}
                    fullWidth
                  >
                    {countryList.map((itm) => {
                      return <MenuItem value={itm}>{itm}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="Zip-basic"
                  label="Zip"
                  variant="filled"
                  fullWidth
                  placeholder="Zip"
                  name="zip"
                  value={zip}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="company-basic"
                  label="Company"
                  variant="filled"
                  fullWidth
                  placeholder="Company"
                  name="company"
                  value={company}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="latitude-basic"
                  label="Latitude"
                  variant="filled"
                  fullWidth
                  placeholder="Latitude"
                  name="latitude"
                  value={latitude}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="longitude-basic"
                  label="Longitude"
                  variant="filled"
                  fullWidth
                  placeholder="Longitude"
                  name="longitude"
                  value={longitude}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: 20 }}>
              <button
                className={"btn green rounded"}
                style={{ marginRight: 10 }}
              >
                Update
              </button>
              <Link
                class="btn red rounded"
                onClick={() =>
                  confirmdelete(
                    "Confirmation",
                    "Are you sure you want to delete this location ?",
                    () => {
                      deleteLocation(locationId);
                    }
                  )
                }
              >
                Delete
              </Link>
            </div>
          </form>
        </Paper>
      </div>
    </Container>
  );
};

export default EditLocation;
