import React from 'react'

import righttic from '../../assets/images/right-tick.png'
import wrongtic from '../../assets/images/wrong-tick.png'

function FeedbackAlert(props) {
    // console.log('Type : ',props.type);
    let feedbackImage = ''
    if(props.type == 'success')
    {
        feedbackImage = righttic
    }
    else{
        feedbackImage = wrongtic

    }
    return (
        <div className={"feedback-modal custom-modal "}>
            <div className="text-center">

            <img className="feedback-modal-image" src={feedbackImage} />       
            </div>

            <div className={`heading text-center ${props.type}`}>
                {props.type}

            </div>
            <div className={`body text-center ${props.type}`}>
                <p>{props.message}</p>

                {props.children}

            </div>

            <div className="footer text-center">
               
                <button className={props.type == 'success' ? "btn btn-success" : "btn btn-error"} onClick={props.no}>Dismis</button>
            </div>

        </div>
    )
}

export default FeedbackAlert
