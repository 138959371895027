import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { getLocationByLocationIdApi } from "../../API/methods/locationFlow";
import { Paper, Grid, Container } from "@material-ui/core";
import Card from "../../Components/Card";

const ViewLocationDetails = () => {
  const [Location, setlocation] = useState({
    name: "",
    city: "",
    country: "",
    description: "",
    landmark: "",
    latitude: "",
    longitude: "",
    street: "",
    zip: "",
    company: "",
  });
  const {
    city,
    country,
    description,
    landmark,
    latitude,
    longitude,
    street,
    zip,
    company,
  } = Location;
  const { locationId } = useParams();
  useEffect(() => {
    loadLocation();
  }, []);
  const loadLocation = async () => {
    getLocationByLocationIdApi(locationId)
      .then((response) => {
        // console.log(response.data);
        setlocation(response.data);
      })
      .catch((error) => {
        alert("Request Failed");
      });
  };

  return (
    <Container maxWidth="false" disableGutters>
      <Paper style={{ padding: 20 }}>
        <h2 className="mb-5">Location</h2>

        <Grid container spacing={3}>
          <Card Title={"Company"} Data={company} />
          <Card Title={"Description"} Data={description} />
          <Card Title={"Street"} Data={street} />
          <Card Title={"Landmark"} Data={landmark} />
          <Card Title={"City"} Data={city} />
          <Card Title={"Country"} Data={country} />
          <Card Title={"Zip"} Data={zip} />
          <Card Title={"Latitude"} Data={latitude} />
          <Card Title={"Longitude"} Data={longitude} />
        </Grid>
      </Paper>
    </Container>
  );
};
export default ViewLocationDetails;
