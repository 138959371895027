import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../Location//Locations.css";
import { feedbackAlert, confirmdelete } from "../../Components/Alerts/Alerts";
import {
  getChargerByChargerBoxKeyApi,
  deleteChargerApi,
  updateChargerApi,
} from "../../API/methods/chargerFlow";
import { Container, Paper, Grid, TextField } from "@material-ui/core";

const EditCharger = () => {
  let history = useHistory();

  const [Charger, setCharger] = useState({
    key: "",
    locationid: "",
    street: "",
    city: "",
    country: "",
    zip: "",
    landmark: "",
    latitude: "",
    longitude: "",
    chargerboxserialno: "",
    chargerid: "",
    chargerpointserialno: "",
    diagnosticstatus: "",
    diagnosticstimestamp: "",
    endAddress: "",
    firmwareupdatestatus: "",
    firmwareupdatedtimestamp: "",
    firmwareversion: "",
    iccid: "",
    imsi: "",
    lastHeartbeattimestamp: "",
    metersserialno: "",
    metertype: "",
    model: "",
    note: "",
    ocppprotocol: "",
    status: "",
    vendor: "",
    description: "",
    heartbeatinterval: "",
    connectoroneimage: undefined,
    connectortwoimage: undefined,
    connectorone: "",
    connectortwo: "",
  });

  const {
    key,
    locationid,
    street,
    city,
    country,
    zip,
    landmark,
    latitude,
    longitude,
    chargerboxserialno,
    chargerid,
    chargerpointserialno,
    diagnosticstatus,
    diagnosticstimestamp,
    endAddress,
    firmwareupdatestatus,
    firmwareupdatedtimestamp,
    firmwareversion,
    iccid,
    imsi,
    lastHeartbeattimestamp,
    metersserialno,
    metertype,
    model,
    note,
    ocppprotocol,
    status,
    vendor,
    description,
    heartbeatinterval,
    connectoroneimage,
    connectortwoimage,
    connectorone,
    connectortwo,
  } = Charger;

  const { chargeBoxKey } = useParams();
  useEffect(() => {
    LoadCharger();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      window.confirm("Are you sure you want to update this charger details")
    ) {
      const payLoad = {
        key: key,
        locationId: locationid,
        street: street,
        city: city,
        country: country,
        zip: zip,
        landmark: landmark,
        latitude: latitude,
        longitude: longitude,
        chargerBoxSerialNo: chargerboxserialno,
        chargerId: chargerid,
        chargerPointSerialNo: chargerpointserialno,
        diagnosticStatus: diagnosticstatus,
        diagnosticsTimeStamp: diagnosticstimestamp,
        endAddress: endAddress,
        firmwareUpdateStatus: firmwareupdatestatus,
        firmwareUpdatedTimeStamp: firmwareupdatedtimestamp,
        firmwareVersion: firmwareversion,
        iccid: iccid,
        imsi: imsi,
        lastHeartBeatTimeStamp: lastHeartbeattimestamp,
        metersSerialNo: metersserialno,
        meterType: metertype,
        model: model,
        note: note,
        ocppProtocol: ocppprotocol,
        status: status,
        vendor: vendor,
        description: description,
        heartBeatInterval: heartbeatinterval,
        connectorOneImage: connectoroneimage,
        connectorTwoImage: connectortwoimage,
        connectorOne: connectorone,
        connectorTwo: connectortwo,
      };
      updateChargerApi(chargeBoxKey, payLoad)
        .then((response) => {
          setCharger(response.data);
          if (response.status == 200) {
            alert("charger details updated successfully");
          } else {
            alert("Error occurred while updating Charger Details ");
          }
          history.push("/LocationManagement");
        })
        .catch((error) =>
          alert("Error occurred while updating Charger Details ")
        );
    }
  };

  const deleteCharger = async (name) => {
    // if (window.confirm("Are you sure you want to delete this Charger")) {
    deleteChargerApi(chargeBoxKey)
      .then((response) => {
        setCharger(response.data);
        if (response.status == 200) {
          // alert("Successfully Deleted Charger");
          history.push("/LocationManagement");
        }
      })
      .catch((error) =>
        feedbackAlert("error", "Error occurred while deleting Charger", () => {
          history.push("/LocationManagement");
        })
      );
    // }
  };
  const LoadCharger = async () => {
    getChargerByChargerBoxKeyApi(chargeBoxKey)
      .then((response) => {
        // console.log("getChargerByChargerBoxKey response:", response.data);
        setCharger(response.data);
      })
      .catch((error) => {
        alert("Request Failed");
      });
  };
  const onInputChange = (e) => {
    const eventName = e.target.name;
    // console.log(eventName, Charger);
    setCharger({ ...Charger, [e.target.name]: e.target.value });
  };

  return (
    <Container maxWidth="false" disableGutters>
      <div className="form-container">
        <Paper style={{ padding: 20 }}>
          <h2 className="text-center mb-4">Charger Details</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Vendor-basic"
                  label="Vendor"
                  variant="filled"
                  fullWidth
                  name="vendor"
                  value={vendor}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Model-basic"
                  label="Model"
                  variant="filled"
                  fullWidth
                  name="model"
                  value={model}
                  onChange={(e) => onInputChange(e)}
                  required
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="chargerBoxSerialNo-basic"
                  label="Charger Box Serial No."
                  variant="filled"
                  fullWidth
                  name="chargerboxserialno"
                  value={chargerboxserialno}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="chargerPointSerialNo-basic"
                  label="Charger Point Serial No."
                  variant="filled"
                  fullWidth
                  name="chargerpointserialno"
                  value={chargerpointserialno}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="ChargerId-basic"
                  label="Charger Id"
                  variant="filled"
                  fullWidth
                  name="chargerid"
                  value={chargerid}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="diagnosticsStatus-basic"
                  label="Diagnostics Status"
                  variant="filled"
                  fullWidth
                  name="diagnosticstatus"
                  value={diagnosticstatus}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="endAddress-basic"
                  label="End Address"
                  variant="filled"
                  fullWidth
                  name="endAddress"
                  value={endAddress}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="diagnosticsTimestamp-basic"
                  label="Diagnostics Timestamp"
                  variant="filled"
                  fullWidth
                  name="diagnosticstimestamp"
                  value={diagnosticstimestamp}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="firmwareUpdatedTimestamp-basic"
                  label="Firmware Updated Timestamp"
                  variant="filled"
                  fullWidth
                  name="firmwareupdatedtimestamp"
                  value={firmwareupdatedtimestamp}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="firmwareUpdateStatus-basic"
                  label="Firmware Update Status"
                  variant="filled"
                  fullWidth
                  name="firmwareupdatestatus"
                  value={firmwareupdatestatus}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="firmwareVersion-basic"
                  label="Firmware Version"
                  variant="filled"
                  fullWidth
                  name="firmwareversion"
                  value={firmwareversion}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="iccid-basic"
                  label="ICCID"
                  variant="filled"
                  fullWidth
                  name="iccid"
                  value={iccid}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="imsi-basic"
                  label="IMSI"
                  variant="filled"
                  fullWidth
                  name="imsi"
                  value={imsi}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="heartbeatinterval-basic"
                  label="Heartbeat Interval"
                  variant="filled"
                  fullWidth
                  name="heartbeatinterval"
                  value={heartbeatinterval}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastHeartBeatTimestamp-basic"
                  label="LastHeartBeatTimestamp"
                  variant="filled"
                  fullWidth
                  name="lastHeartbeattimestamp"
                  value={lastHeartbeattimestamp}
                  onChange={(e) => onInputChange(e)}
                  disabled
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="metersSerialNo-basic"
                  label="Meters Serial No."
                  variant="filled"
                  fullWidth
                  name="metersserialno"
                  value={metersserialno}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="meterType-basic"
                  label="Meter Type"
                  variant="filled"
                  fullWidth
                  name="metertype"
                  value={metertype}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="note-basic"
                  label="Note"
                  variant="filled"
                  fullWidth
                  name="note"
                  value={note}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="ocppProtocol-basic"
                  label="Ocpp Protocol"
                  variant="filled"
                  fullWidth
                  name="ocppprotocol"
                  value={ocppprotocol}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="status-basic"
                  label="Status"
                  variant="filled"
                  fullWidth
                  name="status"
                  value={status}
                  onChange={(e) => onInputChange(e)}
                  size="small"
                />
              </Grid>
            </Grid>
            <button className="btn green mt-4">Update</button>
            <Link
              class="btn red rounded mt-4"
              onClick={() =>
                confirmdelete(
                  "Confirmation",
                  "Are you sure you want to delete this Charger ?",
                  () => {
                    deleteCharger(Charger.chargeBoxKey);
                  }
                )
              }
            >
              Delete
            </Link>
          </form>
        </Paper>
      </div>
    </Container>
  );
};

export default EditCharger;
