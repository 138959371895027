import React, { useContext, useState } from "react";
import { getUserDataFromLocalStorage } from "../Access/configAccess";

const LoginContext = React.createContext();
const LoginUpdateContext = React.createContext();

export function useLogin() {
  return useContext(LoginContext);
}
export function useUpdateLogin() {
  return useContext(LoginUpdateContext);
}

function checkLogin() {
  const token = localStorage.getItem("token");
  if (token === null || token === "") return false;
  let user = getUserDataFromLocalStorage();
  if (user === null) return false;
  return true;
}

export function LoginProvider({ children }) {
  const [isLogin, setIsLogin] = useState(checkLogin);

  function updateIsLogin(value) {
    // console.log("updateIsLogin run");
    setIsLogin(value);
  }

  return (
    <LoginContext.Provider value={isLogin}>
      <LoginUpdateContext.Provider value={updateIsLogin}>
        {children}
      </LoginUpdateContext.Provider>
    </LoginContext.Provider>
  );
}
