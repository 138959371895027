import React from "react";
import { Grid } from "@material-ui/core";

const Card = ({ Title, Data, bigTitle = false }) => {
//   const ITEM_SM = bigTitle ? 6 : 3;
  const ITEM_SM = 6
  return (
    <Grid item container xs={12} sm={12} className="my-1 border border-secondar">
      {Title && (
        <Grid item xs={12} sm={ITEM_SM} className="h6">
          {Title} :
        </Grid>
      )}
      <Grid item xs={12} sm={ITEM_SM}>
        {Data}
      </Grid>
    </Grid>
  );
};

export default Card;
