import CryptoJS, { AES } from "crypto-js";

//Use capital letters for values to avoid any conflict
export const USER = {
  SUPERADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  OPERATOR: "OPERATOR",
  USER: "USER",
};

export function getUser(role) {
  switch (role.toUpperCase()) {
    case USER.ADMIN:
      return USER.ADMIN;
    case USER.SUPERADMIN:
      return USER.SUPERADMIN;
    case USER.OPERATOR:
      return USER.OPERATOR;
    default: {
      // alert("Invalid User Role");
      return USER.USER;
    }
  }
}

export function getRolefromLocal() {
  const user = getUserDataFromLocalStorage();
  if (user === null) return null;
  const role = getUser(user.role);
  return role;
}

export function checkRole(role) {
  for (let prop in USER) {
    if (!USER.hasOwnProperty(prop)) {
      continue;
    }
    if (role === USER[prop]) return true;
  }
  return false;
}

export function setUserDataToLocalStorage(data) {
  // Encrypt
  let SECRET_KEY = data.token;
  let ciphertext = AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  // console.log("Encrypted data:   ", ciphertext);
  localStorage.setItem("user", ciphertext);
}

export function getUserDataFromLocalStorage() {
  const token = localStorage.getItem("token");
  const ciphertext = localStorage.getItem("user");
  if (ciphertext === null) return null;
  // Decrypt
  let SECRET_KEY = token;
  let bytes = AES.decrypt(ciphertext, SECRET_KEY);
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log("Dcrypted data:   ", decryptedData);
  return decryptedData;
}
