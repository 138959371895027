const ApiConstants = {
  BASE_URL:
    "https://www.evitonline.com",

  AUTH: {
    LOGIN: "/authenticate",
    LOGOUT: "/logout",
    MOBILE_LOGIN:
      "https://www.evitonline.com/user/mobile/auth/authenticateUser",
  },
  LOCATION: {
    GET_LOCATION: "/api/location/getAllLocations",
    GET_LOCATION_BY_COMPANY: "/api/location/getAllLocationsByCompany/",
    CREATE_LOCATION: "/api/location/createLocation",
    GET_LOCATION_BY_LOCATIONID: "/api/location/getLocation/",
    UPDATE_LOCATION: "/api/location/updateLocation/",
    DELETE_LOCATION: "/api/location/deleteLocation/",
  },
  STATION: {
    GET_ALL_CHARGERS: "/api/charger/getAllChargers",
  },
  PRICING: {
    GET_PRICING_BY_LOCATIONID: "/api/location/charger/getPricing/",
    GET_PRICING_BY_PRICEID: "/api/location/charger/getPricingById/",
    UPDATE_PRICING: "/api/location/charger/updatePricing/",
  },
  CHARGER: {
    GET_CHARGER_BY_CHARGERBOXKEY: "/api/charger/getCharger/",
    CREATE_CHARGER: "/api/charger/createCharger",
    DELETE_CHARGER: "/api/charger/deleteCharger/",
    UPDATE_CHARGER: "/api/charger/updateCharger/",
    GET_IMAGE_UPLOAD_URL: '/url'
  },
  TRANSACTIONS: {
    GET_ALL_TRANSACTIONS: "/api/charger/transactions",
    GET_TRANSACTIONS : "/api/charger/transaction/"
  },
  USER: {
    ADD_OPERATOR: "/cms/user/addOperatorToLocation",
    ADD_USER: "/cms/user/createUser",
    GET_OPERATOR_BY_LOCATION: "/cms/user/getAllLocationOperators/",
    DELETE_OPERATOR: "/cms/user/deleteOperator/",
  },
};

export default ApiConstants;
